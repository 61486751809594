import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { CheckAuth } from 'src/Services/utility.service';
import { ToastrService } from 'ngx-toastr';
import { Title } from '@angular/platform-browser';
import { AdminUserType, ToolEnum } from 'src/Models/Common';
import { FormGroup, FormBuilder, FormControl } from '@angular/forms';
import { I360Service } from 'src/Services/i360Service';
import { StateMasterService } from 'src/Services/state-master.service';
import { Params, ActivatedRoute } from '@angular/router';
import { BriefingMasterModel } from 'src/Models/BriefingMasterModel';
import { ViewChild } from '@angular/core';
import { BriefingTypesModel } from 'src/Models/BriefingTypesModel';
import { BriefingSubtypesModel } from 'src/Models/BriefingSubtypesModel';
import { BriefingStateMonitoringModel } from 'src/Models/BriefingStateMonitoringModel';
import { UserModel } from 'src/Models/usersModel';
import { ClientCompanyModel } from 'src/Models/ClientCompanyModel';
import { UpdatesService } from 'src/Services/updatesService';
import { UsersService } from 'src/Services/users.service';
import { ActionItemService } from 'src/Services/actionitem.service';

@Component({
  selector: 'app-manage-briefing',
  templateUrl: './manage-briefing.component.html',
  styleUrls: ['./manage-briefing.component.css']
})
export class ManageBriefingComponent implements OnInit {
  briefingMasterModel = new BriefingMasterModel();
  @ViewChild('briefingDoc', { static: true }) briefingDoc;
  @ViewChild('supportedDoc', { static: true }) supportedDoc;

  briefingForm: FormGroup;
  loginuserID: number = 0;
  listOfSelectdBulletinType: BriefingTypesModel[];
  listOfSelectdBulletinSubType: BriefingSubtypesModel[];
  listOfSelectdStateMonitoring: BriefingStateMonitoringModel[];

  BriefingMasterModel: BriefingMasterModel = {
    briefingId: 0,
    stateId: '',
    updateDate: '',
    title: '',
    description: '',
    briefingDocPath: '',
    supportedDocPath: '',
    isActive: '',
    bulletinTypeId: '',
    bulletinSubTypeId: '',
    price: 0.0,
    isPaid: false,
    backupTitle: '',
    notes: '',
    filedAt: '',
    stateMonitoringId: [],
    companyId: [{}],
    companyUsersIds: [{}],
    isPushActionItem: false,
    companyEmailStatus: false,
    sourceNumber: '',
    executiveSummary: '',
    sourceDate: '',
    acquiredDate: ''
  };
  productId: number = 1;
  tokenKey: string = '';
  SelectedTool: string;

  //State List
  dropdownListState = [];
  selectedItemsState = [];
  dropdownSettingsState = {};
  listOfStateIds = [];
  //Briefing Type
  dropdownListBulletinType = [];
  selectedItemsBulletinType = [];
  dropdownSettingsBulletinType = {};
  listOfBulletinTypeIds = [];
  //Bulletin Subtypes
  dropdownListBulletinSubType = [];
  selectedItemsBulletinSubType = [];
  dropdownSettingsBulletinSubType = {};
  listOfBulletinSubTypeIds = [];
  //State Monitoring List
  dropdownListStateMonitoring = [];
  selectedItemsStateMonitoring = [];
  dropdownSettingsStateMonitoring = {};
  listOfStateMonitoringIds = [];

  tempSelectedStateId: number = 0;
  briefingId: number = 0;
  isErrorOnBriefingDoc: boolean;
  isErrorOnSupportedDoc: boolean;

  // Company Dropdown
  clientCompanyList: ClientCompanyModel[];
  dropdownListclientCompany = [];
  selectedItemsclientCompany = [];
  dropdownSettingclientCompany = {};
  listOfCompany = [];

  // User Dropdown
  userModel: UserModel[];
  dropdownListclientCompanyUser = [];
  selectedItemsclientCompanyUser = [];
  dropdownSettingclientCompanyUser = {};
  listOfUsers = [];

  systemUserCondition: string = '';
  isSendEmailNotificationChecked: boolean = false;
  isShowprogressSpinner: boolean = false;
  blockedDocument: boolean = false;
  isMultipleClick: boolean = false;

  //category dropdown
  dropdownSettingsTypes = {};
  dropdownListBriefingCategory = [];
  selectedBriefingCategory = [];
  listOfBriefingCategory = [];
  isDuplicate: boolean = false;

  constructor(private userServices: UsersService, private updatesService: UpdatesService, private activedRoute: ActivatedRoute, private _fb: FormBuilder, private router: Router, private checkAuth: CheckAuth, private titleService: Title,
    private i360service: I360Service,
    private stateMasterService: StateMasterService,
    private toastr: ToastrService,
    private actionItemServices: ActionItemService) {
    this.tokenExpireCalculation(sessionStorage.getItem('appStartTime'), sessionStorage.getItem('tokenExpiryValue'));
    this.tokenKey = sessionStorage.getItem('tokenValue');
    this.SelectedTool = sessionStorage.getItem('UserLoginTool');
    this.productId = ToolEnum[this.SelectedTool];
    this.titleService.setTitle('Manage Briefing Service');
    this.loginuserID = +sessionStorage.getItem('AdminUserId');
    this.briefingMasterModel.price = 40;
    this.briefingForm = this._fb.group({
      briefingId: 0,
      stateId: '',
      updateDate: '',
      title: '',
      description: '',
      briefingDocPath: '',
      supportedDocPath: '',
      isActive: true,
      bulletinTypeId: [],
      bulletinSubTypeId: [],
      price: '',
      isPaid: false,
      backupTitle: '',
      notes: '',
      filedAt: '',
      stateMonitoringId: [],
      companyId: [{}],
      companyUsersIds: [{}],
      companyEmailStatus: 0,
      isPushActionItem: false,
      sourceNumber: '',
      briefingCategory: '',
      executiveSummary: '',
      sourceDate:'',
      acquiredDate:''
    });

    if (AdminUserType.systemadmin == parseInt(sessionStorage.getItem('adminTypeId')))
      this.systemUserCondition = 'null'
    else
      this.systemUserCondition = '  (CompanyId IN (Select CompanyId From [SystemUserCompany] Where [SystemUserCompany].UserId = ' + this.loginuserID + '))';

    this.isDuplicate = sessionStorage.getItem('isDuplicate') == 'true' ? true : false;
    if (sessionStorage.getItem('isDuplicate') != null)
      sessionStorage.removeItem('isDuplicate');
  }

  tokenExpireCalculation(startDate: string, tokenExpiry: string) {
    this.checkAuth.tokenExpireCalculation(startDate, tokenExpiry);
  }

  ngOnInit() {

    this.activedRoute.params.subscribe((params: Params) => {
      if (params['id']) {
        if (params['id'] != null) {

          this.briefingId = params['id'];
          this.GetBriefingData();
        }
        else {
          this.GetStateData();
          this.GetBulletinTypeData();
          this.GetBulletinSubTypeData();
          this.GetClientCompany();
          this.BriefingCategories();
        }
      }
      else {
        this.GetStateData();
        this.GetBulletinTypeData();
        this.GetBulletinSubTypeData();
        this.GetClientCompany();
        this.BriefingCategories();
      }
    });

  }


  GetBriefingData(): void {
    this.tokenKey = sessionStorage.getItem('tokenValue');
    this.isShowprogressSpinner = true;
    this.blockedDocument = true;
    this.i360service.i360BriefingMasterFindbyBriefingId(this.tokenKey, this.briefingId).subscribe(
      data => {
        if (data != null) {
          this.isShowprogressSpinner = false;
          this.blockedDocument = false;
          this.briefingMasterModel = data["briefingData"];
          if (this.briefingMasterModel != null) {
            this.briefingForm.setValue({
              briefingId: this.briefingMasterModel.briefingId,
              stateId: this.briefingMasterModel.stateId,
              updateDate: this.isDuplicate ? null : this.dateFormat(new Date(this.briefingMasterModel.updateDate)),
              title: this.briefingMasterModel.title,
              description: this.briefingMasterModel.description,
              briefingDocPath: this.briefingMasterModel.briefingDocPath,
              supportedDocPath: this.briefingMasterModel.supportedDocPath,
              isActive: this.briefingMasterModel.isActive,
              bulletinTypeId: '',
              bulletinSubTypeId: '',
              price: this.briefingMasterModel.price,
              isPaid: this.briefingMasterModel.isPaid,
              backupTitle: this.briefingMasterModel.backupTitle,
              notes: this.briefingMasterModel.notes,
              filedAt: this.briefingMasterModel.filedAt,
              stateMonitoringId: [],
              sourceNumber: this.briefingMasterModel.sourceNumber,
              companyEmailStatus: this.isSendEmailNotificationChecked,
              isPushActionItem: this.briefingMasterModel.isPushActionItem,
              companyId: [],
              companyUsersIds: [],
              briefingCategory: '',
              executiveSummary: this.briefingMasterModel.executiveSummary,
              sourceDate: this.briefingMasterModel.sourceDate,
              acquiredDate: this.briefingMasterModel.acquiredDate,
            });

            this.listOfStateIds = [];
            if (this.briefingMasterModel.stateId != null)
              this.listOfStateIds.push(this.briefingMasterModel.stateId);
            this.tempSelectedStateId = this.briefingMasterModel.stateId;
            this.listOfSelectdBulletinType = data["briefingData"].listOfSelectdBulletinType;
            this.listOfSelectdBulletinSubType = data["briefingData"].listOfSelectdBulletinSubType;
            this.listOfSelectdStateMonitoring = data["briefingData"].listOfSelectdStateMonitoring;
            if (this.briefingMasterModel.isPaid == true) {
              this.briefingMasterModel.price = 0;
              this.briefingForm.controls['price'].disable();
            }
            else {
              this.briefingForm.controls['price'].enable();
            }
            this.listOfCompany = [];
            if (data["briefingData"].companyId != null && data["briefingData"].companyId != '') {
              this.selectedItemsclientCompany.push({ "id": parseInt(data["briefingData"].companyId), "itemName": data["briefingData"].companyName });
              this.listOfCompany.push(parseInt(data["briefingData"].companyId));
              this.GetCompanyUser(parseInt(data["briefingData"].companyId));
            }

            this.selectedItemsclientCompanyUser = [];
            this.listOfUsers = [];
            if (data["briefingData"].briefingUserlist != null) {
              if (data["briefingData"].briefingUserlist.length > 0) {
                for (let iCounter = 0; iCounter < data["briefingData"].briefingUserlist.length; iCounter++) {
                  if (parseInt(data["briefingData"].briefingUserlist[iCounter].userId) != 0) {
                    this.selectedItemsclientCompanyUser.push({ "id": parseInt(data["briefingData"].briefingUserlist[iCounter].userId), "itemName": data["briefingData"].briefingUserlist[iCounter].userName });
                    this.listOfUsers.push(parseInt(data["briefingData"].briefingUserlist[iCounter].userId));
                  }
                }
              }
            }

            this.listOfStateIds = [];
            this.selectedItemsState = [];
            if (data["briefingData"].briefingStateslist != null && data["briefingData"].briefingStateslist != '') {
              if (data["briefingData"].briefingStateslist.length > 0) {
                for (let iCounter = 0; iCounter < data["briefingData"].briefingStateslist.length; iCounter++) {
                  if (parseInt(data["briefingData"].briefingStateslist[iCounter].userId) != 0) {
                    this.selectedItemsState.push({ "id": parseInt(data["briefingData"].briefingStateslist[iCounter].stateId), "itemName": data["briefingData"].briefingStateslist[iCounter].stateName });
                    this.listOfStateIds.push(parseInt(data["briefingData"].briefingStateslist[iCounter].stateId));
                  }
                }
              }
            }

            this.selectedBriefingCategory = [];
            this.listOfBriefingCategory = [];

            if (data["briefingData"].briefingCategorylist != null && data["briefingData"].briefingCategorylist.length > 0) {
              for (let iCounter = 0; iCounter < data["briefingData"].briefingCategorylist.length; iCounter++) {
                if (parseInt(data["briefingData"].briefingCategorylist[iCounter].categoryId) != 0) {
                  this.selectedBriefingCategory.push({ "id": data["briefingData"].briefingCategorylist[iCounter].categoryId, "itemName": data["briefingData"].briefingCategorylist[iCounter].categoryName });
                  this.listOfBriefingCategory.push(data["briefingData"].briefingCategorylist[iCounter].categoryId);
                }
              }
            }
          }
          this.GetStateData();
          this.GetBulletinTypeData();
          this.GetBulletinSubTypeData();
          this.GetClientCompany();
          this.BriefingCategories();
        }
      }, error => {
        this.isShowprogressSpinner = false;
        this.blockedDocument = false;
        this.showMessage(error, 'Error', 'error');
      })
  }

  GetStateData(): void {
    this.tokenKey = sessionStorage.getItem('tokenValue');
    this.stateMasterService.getState(this.tokenKey, this.productId).subscribe(
      data => {
        if (data["state"] != null) {
          // Set All Item
          for (let iCounter = 0; iCounter < data["state"].length; iCounter++) {
            this.dropdownListState.push(
              { "id": data["state"][iCounter].stateId, "itemName": data["state"][iCounter].name }
            );
            this.dropdownListStateMonitoring.push(
              { "id": data["state"][iCounter].stateId, "itemName": data["state"][iCounter].name }
            );
          }
          this.selectedItemsStateMonitoring = [];
          if (this.listOfSelectdStateMonitoring != null) {
            if (this.listOfSelectdStateMonitoring.length > 0) {
              for (let iCounter = 0; iCounter < this.listOfSelectdStateMonitoring.length; iCounter++) {
                let record = data['state'].filter(m => m.stateId == this.listOfSelectdStateMonitoring[iCounter].stateId)
                if (record != null) {
                  this.selectedItemsStateMonitoring.push({ "id": record[0].stateId, "itemName": record[0].name });
                  this.listOfStateMonitoringIds.push(record[0].stateId);
                }
              }
            }
          }
        }
        else {
          this.showMessage(data["message"], 'Error', 'error');
        }
        this.dropdownSettingsState = {
          singleSelection: false,
          text: "Select Jurisdiction(s)",
          enableSearchFilter: true,
          classes: "borderLessTextBoxGreen",
          badgeShowLimit: 3,
          noDataLabel: 'No Jurisdiction available',
          showCheckbox: true
        };

        this.dropdownSettingsStateMonitoring = {
          singleSelection: false,
          text: "Select Monitoring Jurisdiction(s)",
          enableSearchFilter: true,
          classes: "borderLessTextBoxGreen",
          badgeShowLimit: 3,
          noDataLabel: 'No monitoring Jurisdiction(s) available',
          showCheckbox: true
        };
      }, error => {
        this.showMessage(error, 'Error', 'error');
      })
  }

  removeElemetninArray(array, element) {
    return array.filter(e => e !== element);
  }

  onSelectState($event) {
    this.listOfStateIds = this.removeElemetninArray(this.listOfStateIds, $event.id)
    this.listOfStateIds.push($event.id);
  }

  onDeSelectState($event) {
    if (this.listOfStateIds != null) {
      if (this.listOfStateIds.length > 0) {
        this.listOfStateIds = this.removeElemetninArray(this.listOfStateIds, $event.id)
      }
    }
  }

  onSelectAll($event) {
    for (let iCounter = 0; iCounter < $event.length; iCounter++) {
      if (this.listOfStateIds != null) {
        if (this.listOfStateIds.length > 0) {
          this.listOfStateIds = this.removeElemetninArray(this.listOfStateIds, $event[iCounter].id)
          this.listOfStateIds.push($event[iCounter].id);
        }
        else {
          this.listOfStateIds.push($event[iCounter].id);
        }
      }
    }
  }

  onDeSelectAllState($event) {
    this.listOfStateIds = [];
  }

  onSelectStateMonitoring($event) {
    this.listOfStateMonitoringIds = this.removeElemetninArray(this.listOfStateMonitoringIds, $event.id)
    this.listOfStateMonitoringIds.push($event.id);
  }

  onDeSelectStateMonitoring($event) {
    if (this.listOfStateMonitoringIds != null) {
      if (this.listOfStateMonitoringIds.length > 0) {
        this.listOfStateMonitoringIds = this.removeElemetninArray(this.listOfStateMonitoringIds, $event.id)
      }
    }
  }

  onSelectAllStateMonitoring($event) {
    for (let iCounter = 0; iCounter < $event.length; iCounter++) {
      if (this.listOfStateMonitoringIds != null) {
        if (this.listOfStateMonitoringIds.length > 0) {
          this.listOfStateMonitoringIds = this.removeElemetninArray(this.listOfStateMonitoringIds, $event[iCounter].id)
          this.listOfStateMonitoringIds.push($event[iCounter].id);
        }
        else {
          this.listOfStateMonitoringIds.push($event[iCounter].id);
        }
      }
    }
  }

  onDeSelectAllStateMonitoring($event) {
    this.listOfStateMonitoringIds = [];
  }

  GetBulletinTypeData(): void {
    this.tokenKey = sessionStorage.getItem('tokenValue');
    this.i360service.i360BulletinTypeGet(this.tokenKey).subscribe(
      data => {
        if (data["listOfBulletinType"] != null) {
          // Set All Item
          for (let iCounter = 0; iCounter < data["listOfBulletinType"].length; iCounter++) {
            this.dropdownListBulletinType.push(
              { "id": data["listOfBulletinType"][iCounter].carrierCatId, "itemName": data["listOfBulletinType"][iCounter].carrierCatName }
            );
          }
          this.selectedItemsBulletinType = [];
          if (this.listOfSelectdBulletinType != null) {
            if (this.listOfSelectdBulletinType != null) {
              if (this.listOfSelectdBulletinType.length > 0) {
                for (let iCounter = 0; iCounter < this.listOfSelectdBulletinType.length; iCounter++) {
                  let record = data['listOfBulletinType'].filter(m => m.carrierCatId == this.listOfSelectdBulletinType[iCounter].bulletinTypeId);
                  if (record != null) {
                    this.selectedItemsBulletinType.push({ "id": record[0].carrierCatId, "itemName": record[0].carrierCatName });
                    this.listOfBulletinTypeIds.push(record[0].carrierCatId);
                  }
                }
              }
            }
          }
        }
        else {
          this.showMessage(data["message"], 'Error', 'error');
        }
        this.dropdownSettingsBulletinType = {
          singleSelection: false,
          text: "Select Briefing Types",
          enableSearchFilter: true,
          classes: "borderLessTextBoxGreen",
          badgeShowLimit: 3,
          noDataLabel: 'No briefing types available',
          showCheckbox: true
        };
      }, error => {
        this.showMessage(error, 'Error', 'error');
      })
  }

  onSelectBulletinType($event) {
    this.listOfBulletinTypeIds = this.removeElemetninArray(this.listOfBulletinTypeIds, $event.id)
    this.listOfBulletinTypeIds.push($event.id);
  }

  onDeSelectBulletinType($event) {
    if (this.listOfBulletinTypeIds != null) {
      if (this.listOfBulletinTypeIds.length > 0) {
        this.listOfBulletinTypeIds = this.removeElemetninArray(this.listOfBulletinTypeIds, $event.id)
      }
    }
  }

  onSelectAllBulletinType($event) {
    for (let iCounter = 0; iCounter < $event.length; iCounter++) {
      if (this.listOfBulletinTypeIds != null) {
        if (this.listOfBulletinTypeIds.length > 0) {
          this.listOfBulletinTypeIds = this.removeElemetninArray(this.listOfBulletinTypeIds, $event[iCounter].id)
          this.listOfBulletinTypeIds.push($event[iCounter].id);
        }
        else {
          this.listOfBulletinTypeIds.push($event[iCounter].id);
        }
      }
    }
  }

  onDeSelectAllBulletinType($event) {
    this.listOfBulletinTypeIds = [];
  }

  GetBulletinSubTypeData(): void {
    this.tokenKey = sessionStorage.getItem('tokenValue');
    this.i360service.i360BulletinSubTypeGet(this.tokenKey).subscribe(
      data => {
        if (data["listOfBulletinSubType"] != null) {
          // Set All Item
          for (let iCounter = 0; iCounter < data["listOfBulletinSubType"].length; iCounter++) {
            this.dropdownListBulletinSubType.push({ "id": data["listOfBulletinSubType"][iCounter].bulletinSubTypeId, "itemName": data["listOfBulletinSubType"][iCounter].bulletinSubType });
          }


          this.selectedItemsBulletinSubType = [];
          if (this.listOfSelectdBulletinSubType != null) {
            if (this.listOfSelectdBulletinSubType != null) {
              if (this.listOfSelectdBulletinSubType.length > 0) {
                for (let iCounter = 0; iCounter < this.listOfSelectdBulletinSubType.length; iCounter++) {
                  let record = data['listOfBulletinSubType'].filter(m => m.bulletinSubTypeId == this.listOfSelectdBulletinSubType[iCounter].bulletinSubTypeId)
                  if (record != null) {
                    this.selectedItemsBulletinSubType.push({ "id": record[0].bulletinSubTypeId, "itemName": record[0].bulletinSubType });
                    this.listOfBulletinSubTypeIds.push(record[0].bulletinSubTypeId);
                  }
                }
              }
            }
          }
        }
        else {
          this.showMessage(data["message"], 'Error', 'error');
        }
        this.dropdownSettingsBulletinSubType = {
          singleSelection: false,
          text: "Select Briefing Sub Types",
          enableSearchFilter: true,
          classes: "borderLessTextBoxGreen",
          badgeShowLimit: 3,
          noDataLabel: 'No briefing sub types available',
          showCheckbox: true
        };
      }, error => {
        this.showMessage(error, 'Error', 'error');
      })
  }

  onSelectBulletinSubType($event) {
    this.listOfBulletinSubTypeIds = this.removeElemetninArray(this.listOfBulletinSubTypeIds, $event.id)
    this.listOfBulletinSubTypeIds.push($event.id);
  }

  onDeSelectBulletinSubType($event) {
    if (this.listOfBulletinSubTypeIds != null) {
      if (this.listOfBulletinSubTypeIds.length > 0) {
        this.listOfBulletinSubTypeIds = this.removeElemetninArray(this.listOfBulletinSubTypeIds, $event.id)
      }
    }
  }

  onSelectAllBulletinSubType($event) {
    for (let iCounter = 0; iCounter < $event.length; iCounter++) {
      if (this.listOfBulletinSubTypeIds != null) {
        if (this.listOfBulletinSubTypeIds.length > 0) {
          this.listOfBulletinSubTypeIds = this.removeElemetninArray(this.listOfBulletinSubTypeIds, $event[iCounter].id)
          this.listOfBulletinSubTypeIds.push($event[iCounter].id);
        }
        else {
          this.listOfBulletinSubTypeIds.push($event[iCounter].id);
        }
      }
    }
  }

  onDeSelectAllBulletinSubType($event) {
    this.listOfBulletinSubTypeIds = [];
  }

  saveBriefing(briefingForm) {
    this.briefingMasterModel = briefingForm.value;

    event.preventDefault();

    if (this.briefingMasterModel.title == '' || typeof (this.briefingMasterModel.title) == 'undefined' || this.briefingMasterModel.title == null) {
      this.showMessage('Please enter title', 'Title Required', 'error');
      return;
    }

    if (this.briefingMasterModel.description == '' || typeof (this.briefingMasterModel.description) == 'undefined' || this.briefingMasterModel.description == null) {
      this.showMessage('Please enter description', 'Description Required', 'error');
      return;
    }

    if (this.briefingMasterModel.executiveSummary == '' || typeof (this.briefingMasterModel.executiveSummary) == 'undefined' || this.briefingMasterModel.executiveSummary == null) {
      this.showMessage('Please enter executive Summary for Email', 'Executive Summary Required', 'error');
      return;
    }

    if (this.briefingMasterModel.updateDate == '' || typeof (this.briefingMasterModel.updateDate) == 'undefined' || this.briefingMasterModel.updateDate == null) {
      this.showMessage('Please select email date', 'Email Date Required', 'error');
      return;
    }

    if (this.listOfStateIds.length == 0) {
      this.showMessage('Please select Jurisdiction', 'Jurisdiction Required', 'error');
      return;
    }

    if (this.listOfStateMonitoringIds.length == 0) {
      this.showMessage('Please select monitoring Jurisdiction', 'Jurisdiction Required', 'error');
      return;
    }

    // if (this.listOfBriefingCategory.length == 0) {
    //   this.showMessage('Please select category', 'Select Category Required', 'error');
    //   return;
    // }

    if (this.listOfBulletinTypeIds.length == 0) {
      this.showMessage('Please select bulletin  types', 'Select Briefing Types Required', 'error');
      return;
    }

    if (this.listOfBulletinSubTypeIds.length == 0) {
      this.showMessage('Please select briefing sub types', 'Select Briefing Sub Types Required', 'error');
      return;
    }


    if (this.briefingDoc.nativeElement.files.length == 0 && this.briefingMasterModel.briefingId == 0) {
      this.showMessage('Please select briefing document', 'Briefing Document Required', 'error');
      return;
    }

    if (this.supportedDoc.nativeElement.files.length == 0 && this.briefingMasterModel.briefingId == 0) {
      this.showMessage('Please select source document', 'Source Document Required', 'error');
      return;
    }

    if (this.isErrorOnBriefingDoc) {
      this.showMessage('File not valid in briefing document', 'Invalid File', 'error');
      return;
    }

    if (this.isErrorOnSupportedDoc) {
      this.showMessage('File not valid in source document', 'Invalid File', 'error');
      return;
    }
    
    this.briefingMasterModel.briefingId = this.isDuplicate ? 0 : this.briefingMasterModel.briefingId;
    if (this.isDuplicate) {
      this.briefingMasterModel.isRecordsDuplicates = this.isDuplicate;
      this.briefingMasterModel.duplicatesId = this.briefingId;
    }
    else {
      this.briefingMasterModel.isRecordsDuplicates = this.isDuplicate;
      this.briefingMasterModel.duplicatesId = this.briefingId;
    }

    let dt = new Date(briefingForm.value['updateDate'])
    this.briefingMasterModel.updateDate = this.yyyMMddDate(dt);


    if(briefingForm.value['sourceDate']!='' && briefingForm.value['sourceDate']!=null && briefingForm.value['sourceDate']!=undefined){
      let dtsourceDate = new Date(briefingForm.value['sourceDate'])
      this.briefingMasterModel.sourceDate = this.yyyMMddDate(dtsourceDate);
    }
    else{
      this.briefingMasterModel.sourceDate ="";
    }
    
    if(briefingForm.value['acquiredDate']!='' && briefingForm.value['acquiredDate']!=null && briefingForm.value['acquiredDate']!=undefined){
      let dtacquiredDate = new Date(briefingForm.value['acquiredDate'])
      this.briefingMasterModel.acquiredDate =  this.yyyMMddDate(dtacquiredDate);
    }
    else
    {
      this.briefingMasterModel.acquiredDate ="";
    }

   

    this.briefingMasterModel.createdBy = this.loginuserID;
    this.briefingMasterModel.modifiedBy = this.loginuserID;
    this.briefingMasterModel.stateId = this.listOfStateIds.join(',');
    this.briefingMasterModel.bulletinTypeId = this.listOfBulletinTypeIds.join(',');
    this.briefingMasterModel.bulletinSubTypeId = this.listOfBulletinSubTypeIds.join(',');
    this.briefingMasterModel.stateMonitoringId = this.listOfStateMonitoringIds.join(',');
    if (this.selectedBriefingCategory.length > 0) {
      this.briefingMasterModel.briefingCategoryId = this.selectedBriefingCategory[0].id;
    }

    this.briefingMasterModel.stateName = '';
    this.briefingMasterModel.companyId = this.listOfCompany.join(',');
    this.briefingMasterModel.companyUsersIds = this.listOfUsers.join(',');
    this.briefingMasterModel.companyEmailStatus = this.isSendEmailNotificationChecked;

    let formData = new FormData();
    formData.append('briefingId', this.briefingMasterModel.briefingId);
    formData.append('title', this.briefingMasterModel.title);
    formData.append('stateId', this.briefingMasterModel.stateId);
    formData.append('description', this.briefingMasterModel.description);
    formData.append('updateDate', this.briefingMasterModel.updateDate);
    formData.append('briefingDoc', this.briefingDoc.nativeElement.files[0]);
    formData.append('supportedDoc', this.supportedDoc.nativeElement.files[0]);
    formData.append('createdBy', this.briefingMasterModel.createdBy);
    formData.append('modifiedBy', this.briefingMasterModel.modifiedBy);
    formData.append('isActive', this.briefingMasterModel.isActive);
    formData.append('stateName', this.briefingMasterModel.stateName);
    formData.append('bulletinTypeId', this.briefingMasterModel.bulletinTypeId);
    formData.append('bulletinSubTypeId', this.briefingMasterModel.bulletinSubTypeId);
    formData.append('briefingCategoryId', this.briefingMasterModel.briefingCategoryId);
    formData.append('price', this.briefingMasterModel.price);
    formData.append('isPaid', this.briefingMasterModel.isPaid);
    formData.append('backupTitle', this.briefingMasterModel.backupTitle);
    formData.append('notes', this.briefingMasterModel.notes);
    formData.append('filedAt', this.briefingMasterModel.filedAt);
    formData.append('stateMonitoringId', this.briefingMasterModel.stateMonitoringId);
    formData.append('companyId', this.briefingMasterModel.companyId);
    formData.append('companyUsersIds', this.briefingMasterModel.companyUsersIds);
    formData.append('companyEmailStatus', this.briefingMasterModel.companyEmailStatus);
    formData.append('isPushActionItem', this.briefingMasterModel.isPushActionItem);
    formData.append('sourceNumber', this.briefingMasterModel.sourceNumber);
    formData.append('executiveSummary', this.briefingMasterModel.executiveSummary);
    formData.append('isRecordsDuplicates', this.briefingMasterModel.isRecordsDuplicates);
    formData.append('duplicatesId', this.briefingMasterModel.duplicatesId);
    formData.append('sourceDate', this.briefingMasterModel.sourceDate);
    formData.append('acquiredDate', this.briefingMasterModel.acquiredDate);

    if (this.isMultipleClick) {
      console.log('client clicked on dubble click');
      return;
    }
    this.isMultipleClick = true;

    this.i360service.i360BriefingMasterAddUpdate(this.tokenKey, formData).subscribe(
      data => {

        this.isMultipleClick = false;
        if (data['briefingId'] != 0) {
          if (data['briefingId'] == -1) {
            this.showMessage('Duplicate record found.', 'Duplicate', 'error');
          }
          else {
            if (this.briefingId == 0)
              this.showMessage('Record saved successfully', 'Save', 'success');
            else
              this.showMessage('Record updated successfully', 'Update', 'success');
            this.briefingId = 0;
            this.cancelBriefingServices()
          }

        }
      },
      error => {
        this.isMultipleClick = false;
        console.log(error)
      }
    );
  }

  showMessage(msg: string, msgTitle: string, msgType: string) {
    if (msgType == 'success') {
      setTimeout(() => this.toastr.success(msg, msgTitle));
    }
    else if (msgType == 'error') {
      setTimeout(() => this.toastr.error(msg, msgTitle));
    }
  }

  cancelBriefingServices() {
    this.router.navigate(['admin/briefingServices/list']);
  }

  dateFormat(dt: Date) {
    let dd = dt.getDate().toString();
    if (parseInt(dd.toString()) < 10)
      dd = '0' + dd.toString();

    let mm = (dt.getMonth() + 1).toString();
    if (parseInt(mm.toString()) < 10)
      mm = '0' + mm.toString();

    return mm + '/' + dd + '/' + dt.getFullYear() + ' 00:00:00';
  }

  fileEvent(event, isBriefingDoc): any {
    //*-- this function gets content of uploaded file and validation -- * /    
    var file = event.target.files[0];
    let size = Math.round(file.size / 1024);
    if (size <= 21000) {      
      var allowedExtensions = ["doc", "DOC", "docx", "DOCX", "pdf", "PDF"];
      let fileType = file.name.split('.').pop();

      if (!this.isInArray(allowedExtensions, fileType)) {
        this.showMessage('You have selected an invalid file type.System supports following file formats: PDF, DOC, DOCX.', 'Error', 'error');
        if (isBriefingDoc)
          this.isErrorOnBriefingDoc = true;
        else
          this.isErrorOnSupportedDoc = true;

        window.scrollTo(0, 0);
      }
      else {
        if (isBriefingDoc)
          this.isErrorOnBriefingDoc = false;
        else
          this.isErrorOnSupportedDoc = false;
      }
    }
    else {
      this.showMessage('You have selected a larger file. supports file upto 20MB.', 'Error', 'error');
      if (isBriefingDoc)
        this.isErrorOnBriefingDoc = true;
      else
        this.isErrorOnSupportedDoc = true;
      window.scrollTo(0, 0);
    }
  }
  /*- checks if word exists in array -*/
  isInArray(array, word) {
    return array.indexOf(word.toLowerCase()) > -1;
  }

  yyyMMddDate(dateArray: any) {

    if (dateArray != null) {
      let dd1 = dateArray.getDate().toString();
      if (parseInt(dd1.toString()) < 10)
        dd1 = '0' + dd1.toString();

      let mm1 = (dateArray.getMonth() + 1).toString();
      if (parseInt(mm1.toString()) < 10)
        mm1 = '0' + mm1.toString();

      return dateArray.getFullYear() + '/' + mm1 + '/' + dd1 + ' 00:00:00';
    }
    else
      return null;
  }

  isPaid($event) {
    if ($event.currentTarget.checked) {
      this.briefingMasterModel.price = 0;
      this.briefingForm.controls['price'].disable();
    }
    else {
      this.briefingForm.controls['price'].enable();
    }
  }

  GetClientCompany() {
    this.clientCompanyList = [];
    this.isShowprogressSpinner = true;
    this.blockedDocument = true;
    this.updatesService.GetClientCompany(this.tokenKey, 7, this.systemUserCondition).subscribe(
      data => {
        this.isShowprogressSpinner = false;
        this.blockedDocument = false;
        if (data['status'] == 'Success') {
          this.clientCompanyList = data['clientCompanay'];

          if (this.clientCompanyList != null) {
            if (this.clientCompanyList.length > 0) {
              for (let iCounter = 0; iCounter < this.clientCompanyList.length; iCounter++) {
                this.dropdownListclientCompany.push(
                  { "id": this.clientCompanyList[iCounter].companyId, "itemName": this.clientCompanyList[iCounter].companyName }
                );
              }
            }
          }

          this.dropdownSettingclientCompany = {
            singleSelection: true,
            text: "Select Company",
            enableSearchFilter: true,
            classes: "borderLessTextBoxGreen",
            badgeShowLimit: 2,
            noDataLabel: 'No data available for selected company',
            showCheckbox: false
          };
        }
      },
      error => {
        this.isShowprogressSpinner = false;
        this.blockedDocument = false;
        console.log(error);
      }
    );
  }

  // Company   
  onSelectedCompany($event) {
    this.listOfCompany = [];
    let companyId = $event.id;
    this.selectedItemsclientCompanyUser = [];
    if (this.listOfCompany != null) {
      if (this.listOfCompany.length > 0) {
        this.listOfCompany = this.removeElemetninArray(this.listOfCompany, $event.id)
        this.listOfCompany.push($event.id);
      }
      else {
        this.listOfCompany.push($event.id);
      }
    }
    this.GetCompanyUser(companyId);
  }

  onDeSelectedCompany($event) {
    if (this.listOfCompany != null) {
      if (this.listOfCompany.length > 0) {
        this.listOfCompany = this.removeElemetninArray(this.listOfCompany, $event.id)
      }
    }
  }

  onSelectAllCompany($event) {
    for (let iCounter = 0; iCounter < $event.length; iCounter++) {
      if (this.listOfCompany != null) {
        if (this.listOfCompany.length > 0) {
          this.listOfCompany = this.removeElemetninArray(this.listOfCompany, $event[iCounter].id)
          this.listOfCompany.push($event[iCounter].id);
        }
        else {
          this.listOfCompany.push($event[iCounter].id);
        }
      }
    }
  }

  onDeSelectAllCompany($event) {
    this.dropdownListclientCompanyUser = [];
    this.selectedItemsclientCompanyUser = [];
    this.listOfUsers = [];
    this.listOfCompany = [];
  }

  // Users 
  onSelectedUsers($event) {

    if (this.listOfUsers != null) {
      if (this.listOfUsers.length > 0) {
        this.listOfUsers = this.removeElemetninArray(this.listOfUsers, $event.id)
        this.listOfUsers.push($event.id);
      }
      else {
        this.listOfUsers.push($event.id);
      }
    }
  }

  onDeSelectedUsers($event) {
    if (this.listOfUsers != null) {
      if (this.listOfUsers.length > 0) {
        this.listOfUsers = this.removeElemetninArray(this.listOfUsers, $event.id)
      }
    }
  }

  onSelectAllUsers($event) {
    for (let iCounter = 0; iCounter < $event.length; iCounter++) {
      if (this.listOfUsers != null) {
        if (this.listOfUsers.length > 0) {
          this.listOfUsers = this.removeElemetninArray(this.listOfUsers, $event[iCounter].id)
          this.listOfUsers.push($event[iCounter].id);
        }
        else {
          this.listOfUsers.push($event[iCounter].id);
        }
      }
    }
  }

  onDeSelectAllUsers($event) {
    this.listOfUsers = [];
  }

  GetCompanyUser(companyId: any) {
    this.dropdownListclientCompanyUser = [];
    this.userServices.GetUsersFindByCompanyId(this.tokenKey, companyId, 7).subscribe(
      data => {
        if (data['status'] == 'Success') {
          if (data['companyUsers'] != null) {
            if (data['companyUsers'].length > 0) {
              this.userModel = data['companyUsers'];

              for (let iCounter = 0; iCounter < this.userModel.length; iCounter++) {
                if (this.userModel[iCounter].adminUserId != -1) {
                  this.dropdownListclientCompanyUser.push(
                    { "id": this.userModel[iCounter].userId, "itemName": this.userModel[iCounter].userName }
                  );
                }
              }

              this.dropdownSettingclientCompanyUser = {
                singleSelection: false,
                text: "Select User",
                enableSearchFilter: true,
                classes: "borderLessTextBoxGreen",
                badgeShowLimit: 2,
                noDataLabel: 'No data available for selected user',
                showCheckbox: true
              };

            }
          }
        }
      },
      error => {
        this.showMessage(error, 'Error', 'error');
      }
    );
  }

  BriefingCategories(): void {
    this.dropdownListBriefingCategory = [];
    this.actionItemServices.GetActionItemsType(this.tokenKey).subscribe(
      data => {
        if (data["actionType"] != null && data["status"] != "Failure") {

          for (let iCounter = 0; iCounter < data["actionType"].length; iCounter++) {
            // Remove Company Specific
            if (data["actionType"][iCounter].actionItemTypeId != 5) {
              this.dropdownListBriefingCategory.push(
                { "id": data["actionType"][iCounter].actionItemTypeId, "itemName": data["actionType"][iCounter].typeName }
              );
            }
          }
        }
        else {
          this.showMessage(data["message"], 'Error', 'error');
        }
        this.dropdownSettingsTypes = {
          singleSelection: true,
          text: "Select Category(s)",
          enableSearchFilter: true,
          classes: "borderLessTextBoxGreen",
          badgeShowLimit: 1,
          noDataLabel: 'No  category(s) Available',
          showCheckbox: false,
          enableFilterSelectAll: false,
          enableCheckAll: false
        };
      }, error => {
        this.showMessage(error, 'Error', 'error');
      })
  }

  // Action Type
  onSelectedBriefingCategory($event) {
    this.listOfBriefingCategory = [];
    if (this.listOfBriefingCategory != null) {
      if (this.listOfBriefingCategory.length > 0) {
        this.listOfBriefingCategory = this.removeElemetninArray(this.listOfBriefingCategory, $event.id)
        this.listOfBriefingCategory.push($event.id);
      }
      else {
        this.listOfBriefingCategory.push($event.id);
      }
    }
  }

  onDeSelectedBriefingCategory($event) {
    if (this.listOfBriefingCategory != null) {
      if (this.listOfBriefingCategory.length > 0) {
        this.listOfBriefingCategory = this.removeElemetninArray(this.listOfBriefingCategory, $event.id)
      }
    }
  }

  onDeSelectAllBriefingCategory($event) {
    this.listOfBriefingCategory = [];
  }

  setSendEmailNotificationChecked($event) {
    this.isSendEmailNotificationChecked = $event.target.checked;
  }
}