export class ComplianceReportModel {
  total_Reports: number = 0;
  total_Reports_Filed: number = 0;
  total_Reports_Filed_by_Client: number = 0;
  total_Reports_Not_Sent: number = 0;
  total_Reports_Not_Received: number = 0;
  total_Reports_with_No_Remittance: number = 0;
}

export class ReportFilterModel {
  userName: string;
  specialistId: number;
  startDate: Date;
  endDate: Date;
}

export class BriefingReportModel {
  DisplayDate: string;
  CompanyName: string;
  RecordCount: number = 0;
}

export class BriefingReportFilterModel {
  month: number;
  year: number;
  companyId: number;
  fromDate: any;
  toDate: any;
  stateID: any;
  pageNumber: any;
  briefingTypesId: any;  
}