import { DatePipe } from '@angular/common';
import { analyzeAndValidateNgModules } from '@angular/compiler';
import { Component, HostListener, OnInit } from '@angular/core';
// declare var $: any;
import { NgForm } from '@angular/forms';
import { Title } from '@angular/platform-browser';
import { Router } from '@angular/router';
// import { AngularEditorConfig } from '@kolkov/angular-editor';
import { datepickerAnimation } from 'ngx-bootstrap/datepicker/datepicker-animations';
import { ToastrService } from 'ngx-toastr';
import { stringify } from 'querystring';
import { StateMasterModel } from 'src/Models/stateMasterModel';
import { TRFAContentGetModel, TRFAContentGetModel_v2 } from 'src/Models/TRFAContentModel';
import { TRFAFundModel } from 'src/Models/TRFAFundModel';
import { TRFASourceModel } from 'src/Models/TRFASourceModel';
import { RouterExtService } from 'src/Services/RouterExtService';
import { StateMasterService } from 'src/Services/state-master.service';
import { TrfaCarrierMasterService } from 'src/Services/trfa-carrier--master.service';
import { TrfaCategoryMasterService } from 'src/Services/trfa-category-master.service';
import { TrfaContentMasterService } from 'src/Services/trfa-content-master.service';
import { TrfaFundMasterService } from 'src/Services/trfa-fund-master.service';
import { TrfaMonthsService } from 'src/Services/trfa-months.service';
import { TrfaSourceMasterService } from 'src/Services/trfa-source-master.service';
import { CheckAuth } from 'src/Services/utility.service';
import { Script } from 'vm';
// declare var jQuery: any;

@Component({
  selector: 'app-content-list',
  templateUrl: './content-list.component.html',
  styleUrls: ['./content-list.component.css']
})
export class ContentListComponent implements OnInit {
  ckeConfig: any;
  stateCode: any;
  tokenKey: string;
  listofContent: TRFAContentGetModel_v2[];
  templistofContent: TRFAContentGetModel_v2[];

  PageNumber: number = 1;
  PageSize: number;
  WhereCondition: string;
  OrderBy: string;
  Note: string;


  upDowRuleTextCSS: string = '';
  upDowStateNameCSS: string = '';
  upDowFundNameCSS: string = '';
  upDowCategoryNameCSS: string = '';
  upDowSourceNameCSS: string = '';
  // upDowfundNameCSS: string = '';
  // upDowSubcategoryCSS: string = '';
  // upDowEffectiveDateCSS: string = '';
  // upDowLastAuditCSS: string = '';
  // upDowRuleCitationCSS: string = '';
  isAscending: boolean = false;
  isRecord: boolean = false;
  isloading: boolean = false;
  SelectedTool: string;
  totalRecordCount: number = 0;

  //State List
  dropdownListState = [];
  selectedItemsState = [];
  dropdownSettingsState = {};
  listOfStateIds = [];
  stateId: string = ' ';
  stateList: StateMasterModel[] = [];

  //Fund
  fundID: string = ' ';
  dropdownListFund = [];
  selectedItemsFund = [];
  dropdownSettingsFund = {};
  listOffundIDs = [];
  fundList: TRFAFundModel[] = [];

  EditfundID: string = ' ';
  EditdropdownListFund = [];
  EditselectedItemsFund = [];
  EditdropdownSettingsFund = {};
  EditlistOffundIDs = [];
  EditfundList: TRFAFundModel[] = [];
  EditlistOfFundName = [];


  module = "TRFA";
  stateIds: string = ' ';
  contentData: any;

  //Source
  SourceList: TRFASourceModel[] = [];
  selectedItemsSource = [];
  dropdownListSource = [];
  dropdownSettingsSource = {};
  listOfsource = [];
  sourceTypeId: string = '';

  //Edited Source
  EditSourceList: TRFASourceModel[] = [];
  EditselectedItemsSource = [];
  EditdropdownListSource = [];
  EditdropdownSettingsSource = {};
  EditlistOfsource = [];
  EditsourceTypeId: string = '';
  EditlistOfsourceName = [];

  MonthList: any[] = [];
  selectedItemsMonth = [];
  dropdownSettingsMonthFilter = {};
  dropdownMonthFilter = [];
  auditMonth: any;
  listOfMonth = [];

  MonthList1: any[] = [];
  selectedItemsMonth1 = [];
  dropdownSettingsMonthFilter1 = {};
  dropdownMonthFilter1 = [];
  listOfMonth1 = [];

  carrierList: any[] = [];
  dropdownDateCarrierFilter: any[] = [];
  dropdownSettingsDateCarrierFilter = {};
  selectedItemsCarrier = [];
  listOfCarrierIDs = [];
  listOfCarrierName = [];

  categoryList: any[] = [];
  dropdownDateCategoryFilter: any[] = [];
  dropdownSettingsDateCategoryFilter = {};
  selectedItemsCategory = [];
  listOfCategoryIDs = [];
  listOfCategoryName = [];


  categoryListMain: any[] = [];
  dropdownDateCategoryFilterMain: any[] = [];
  dropdownSettingsDateCategoryFilterMain = {};
  selectedItemsCategoryMain = [];
  listOfCategoryIDsMain = [];
  categoryId: string = '';

  isRowEdited = false;
  changedata: any;
  isSaveData:boolean=false;
  ContentId:number=0;
  userID: any;
  // config: AngularEditorConfig = {
  //   editable: true,
  //   spellcheck: true,
  //   height: '10rem',
  //   minHeight: '5rem',
  //   placeholder: 'Enter text here...',
  //   translate: 'no',
  //   customClasses: [
  //     {
  //       name: "quote",
  //       class: "quote",
  //     },
  //     {
  //       name: 'redText',
  //       class: 'redText'
  //     },
  //     {
  //       name: "titleText",
  //       class: "titleText",
  //       tag: "h1",
  //     },
  //   ],
  //   toolbarHiddenButtons: [
  //     [
  //       'undo',
  //       'redo',
  //       'subscript',
  //       'superscript',
  //       'indent',
  //       'outdent',
  //       'fontName'
  //     ],
  //     [
  //       'fontSize',
  //       'textColor',
  //       'backgroundColor',
  //       'customClasses',
  //       'unlink',
  //       'insertImage',
  //       'insertVideo',
  //       'insertHorizontalRule',
  //       'removeFormat',
  //     ]
  //   ]
  // }

  openMenuIndex: number = -1;

  constructor(private router: Router, private trfaContentMaster: TrfaContentMasterService, private checkAuth: CheckAuth
    , private titleService: Title, private toastr: ToastrService, private stateService: StateMasterService, private trfaFundMaster: TrfaFundMasterService,
    private carrierMasterService: TrfaCarrierMasterService, private categoryMasterService: TrfaCategoryMasterService,
    private ContentService: TrfaContentMasterService, private datePipe: DatePipe,
    private trfaSourceMaster: TrfaSourceMasterService,
    private trfaMonthsService: TrfaMonthsService,
    private routerService: RouterExtService) {
    this.tokenExpireCalculation(sessionStorage.getItem('appStartTime'), sessionStorage.getItem('tokenExpiryValue'));
    this.listofContent = [];
    this.templistofContent = [];
    this.PageNumber = 1;
    this.PageSize = 10;
    this.WhereCondition = 'null';
    this.OrderBy = 'StateName,FundName';
    this.Note = 'null';
    this.SelectedTool = sessionStorage.getItem('UserLoginTool');
    this.titleService.setTitle('Audit Window');
  }

  tokenExpireCalculation(startDate: string, tokenExpiry: string) {
    this.checkAuth.tokenExpireCalculation(startDate, tokenExpiry);
  }

  ngOnInit() {

    this.ckeConfig = {
      versionCheck:false,
      toolbar: [
        {
          items:
            [
              'Undo', 'Redo', 'Link', 'Unlink', 'Anchor',
              'Image', 'Table', 'SpecialChar', 'Maximize', 'Source', 'Bold', 'Italic', 'Underline', 'Strike', 'RemoveFormat',
              'NumberedList', 'BulletedList', 'Outdent', 'Indent', 'Styles', 'Format', 'Font'
            ]
        }],


    }

    this.dropdownSettingsState = {
      singleSelection: false,
      text: "Filter by Jurisdiction",
      enableSearchFilter: true,
      classes: "borderLessTextBoxGreen",
      badgeShowLimit: 1,
      noDataLabel: 'No Jurisdiction(s) available',
      showCheckbox: true
    };
    this.dropdownSettingsFund = {
      singleSelection: false,
      text: "Filter by Fund",
      enableSearchFilter: true,
      classes: "borderLessTextBoxGreen",
      badgeShowLimit: 1,
      noDataLabel: 'No Fund(s) available',
      showCheckbox: true
    };

    this.EditdropdownSettingsFund = {
      singleSelection: true,
      text: "Select Fund",
      enableSearchFilter: true,
      classes: "borderLessTextBoxGreen",
      badgeShowLimit: 1,
      noDataLabel: 'No Fund(s) available',
      showCheckbox: true,
      enableFilterSelectAll: false
    };

    this.dropdownSettingsDateCategoryFilter = {
      singleSelection: true,
      text: "Select category",
      enableSearchFilter: true,
      classes: "borderLessTextBoxGreen",
      badgeShowLimit: 2,
      noDataLabel: 'No Category(s) available',
      showCheckbox: false,
      enableFilterSelectAll: false
    };

    this.dropdownSettingsDateCategoryFilterMain = {
      singleSelection: false,
      text: "Filter by Category",
      enableSearchFilter: true,
      classes: "borderLessTextBoxGreen",
      badgeShowLimit: 1,
      noDataLabel: 'No Category(s) available',
      showCheckbox: true
    };

    this.dropdownSettingsDateCarrierFilter = {
      singleSelection: false,
      text: "Select carriers",
      enableSearchFilter: true,
      classes: "borderLessTextBoxGreen",
      badgeShowLimit: 2,
      noDataLabel: 'No Carrier(s) available',
      showCheckbox: true
    };

    this.dropdownSettingsSource = {
      singleSelection: false,
      text: "Filter by Source",
      enableSearchFilter: true,
      classes: "borderLessTextBoxGreen",
      badgeShowLimit: 1,
      noDataLabel: 'No Source(s) available',
      showCheckbox: true
    };

    this.dropdownSettingsMonthFilter = {
      singleSelection: false,
      text: "Select Month",
      enableSearchFilter: true,
      classes: "borderLessTextBoxGreen",
      badgeShowLimit: 2,
      noDataLabel: 'No Month(s) available',
      showCheckbox: true
    };

    this.dropdownSettingsMonthFilter1 = {
      singleSelection: true,
      text: "Filter by Month",
      enableSearchFilter: true,
      classes: "borderLessTextBoxGreen",
      badgeShowLimit: 2,
      noDataLabel: 'No Month(s) available',
      showCheckbox: false,
      enableFilterSelectAll: false
    };

    $(function () {
      $('.wrapper1').on('scroll', function (e) {
        $('.wrapper2').scrollLeft($('.wrapper1').scrollLeft());
      });
      $('.wrapper2').on('scroll', function (e) {
        $('.wrapper1').scrollLeft($('.wrapper2').scrollLeft());
      });
      $('.div1').width($('table').width());
      $('.div2').width($('table').width());
    });

    // if (this.routerService.getPreviousUrl().includes('admin/trfa/content-list')) {
    //   this.setSaveFilter();
    // }
    // else {
    //   this.clearSession();
    // }

    this.tokenKey = sessionStorage.getItem('tokenValue');
    this.userID = +sessionStorage.getItem('AdminUserId');
    this.getState();
    this.getFund();
    this.OrderBy = 'CategorySort';
    this.GetContent();
    this.getCarrier();
    this.getCategory();
    this.getSource();
    this.GetEditFund();
    this.getMonths1();
    this.isloading = true;
  }

  GetEditFund() {
    this.trfaFundMaster.GetFund(this.tokenKey, this.stateId, true,true).subscribe(
      result => {
        if (result['status'] == 'Success') {
          // this.EditdropdownListFund = []
          this.EditfundList = result['fund'];
        }
      },
      error => { }
    );
  }

  getFund() {
    this.stateId = ' ';
    if (this.listOfStateIds.length > 0) {
      this.stateId = this.listOfStateIds.join(',')
    }
    this.trfaFundMaster.GetFund(this.tokenKey, this.stateId, true,true).subscribe(
      data => {
        this.dropdownListFund = []
        this.fundList = data['fund'];
        this.dropdownListFund = data['fund'];
        this.selectedItemsFund = [];
        if (this.fundList.length > 0) {
          let b = [];
          this.selectedItemsFund = [];
          this.fundList.forEach(x => {
            let a = this.listOffundIDs.filter(e => e == x.fundID);
            if (a.length > 0) {
              b.push(x.fundID);
              this.selectedItemsFund.push(x)
            }
            // this.selectedItemsFund.push(x)
          });
          this.listOffundIDs = b;
        }
        else {
          this.selectedItemsFund = [];
          this.listOffundIDs = [];
        }
      },
      error => { }
    );
  }
  pageChanged($event: any) {
    this.PageNumber = parseInt($event.page);
    this.GetContent();
  }

  getCarrier() {
    this.carrierMasterService.GetCarrier(this.tokenKey).subscribe(
      result => {
        if (result['status'] == 'Success') {
          this.carrierList = result['carrier'];
          this.dropdownDateCarrierFilter = [];
          this.carrierList.forEach(x => {
            this.dropdownDateCarrierFilter.push({ "id": x.carrierTypeId, "itemName": x.carrierName })
          });
        }
      },
      error => { }
    );
  }

  getCategory() {
    this.categoryMasterService.GetCategory(this.tokenKey).subscribe(
      result => {
        if (result['status'] == 'Success') {
          this.categoryList = result['category'];
          this.dropdownDateCategoryFilter = [];

          this.categoryListMain = result['category'];
          this.dropdownDateCategoryFilterMain = [];
          this.categoryList.forEach(x => {
            this.dropdownDateCategoryFilter.push({ "id": x.categoryID, "itemName": x.categoryName })
          });

          this.categoryList.forEach(x => {
            this.dropdownDateCategoryFilterMain.push({ "id": x.categoryID, "itemName": x.categoryName })
          });
        }
      },
      error => { }
    );
  }

  GetContentById(Content) {
    this.listofContent.forEach(element => {
      element['isEdit'] = false;
    });
    this.templistofContent = JSON.parse(JSON.stringify(this.listofContent));
    Content.isEdit = true;
    Content.effectiveDate = new Date(Content.effectiveDate);
    if(Content.lastAudit!=null)
    {
    Content.lastAudit = new Date(Content.lastAudit);
    }

    if (this.EditfundList.length == 0) {
      this.GetEditFund();
    }

    if (this.MonthList.length == 0) {
      this.getMonths();
    }

    this.isRowEdited = true;
    this.trfaContentMaster.GetContentById(this.tokenKey, parseInt(Content.contentID.toString())).subscribe(
      result => {
        if (result['status'] == 'Success') {
          if (this.carrierList.length > 0) {
            this.selectedItemsCarrier = []
            this.listOfCarrierIDs = [];
            this.listOfCarrierName=[];
            for (let iCounter = 0; iCounter < result['content'].carrierTypeID.length; iCounter++) {
              let data = this.carrierList.filter(x => x.carrierTypeId == result['content'].carrierTypeID[iCounter].carrierTypeId)[0];
              this.selectedItemsCarrier.push({ "id": data.carrierTypeId, "itemName": data.carrierName })
              this.listOfCarrierIDs.push(data.carrierTypeId)
              this.listOfCarrierName.push(data.carrierName)
            }
          }

          if (this.categoryList.length > 0) {
            this.selectedItemsCategory = [];
            this.listOfCategoryIDs = [];
            this.listOfCategoryName=[];
            result['content'].categoryID.map((x) => {
              let data = this.categoryList.filter(y => y.categoryID == x.categoryID)[0];
              this.selectedItemsCategory.push({ "id": data.categoryID, "itemName": data.categoryName })
              this.listOfCategoryIDs.push(data.categoryID)
              this.listOfCategoryName.push(data.categoryName);
            })
          }

          if (this.EditfundList.length > 0) {
            let b = [];
            this.EditlistOffundIDs = [];
            this.EditlistOfFundName = [];
            this.EditselectedItemsFund = [];
            this.EditdropdownListFund = this.EditfundList;
            for (let iCounter = 0; iCounter < this.fundList.length; iCounter++) {
              if (this.fundList[iCounter].fundID == result['content'].fundID) {
                this.EditselectedItemsFund.push(this.fundList[iCounter]);
                b.push(this.fundList[iCounter].fundID);
                this.EditlistOfFundName.push(this.fundList[iCounter].fundName);
              }
            }
            this.EditlistOffundIDs = b;
          }
          else {
            this.EditdropdownListFund = [];
            this.EditselectedItemsFund = [];
            this.EditlistOffundIDs = [];
            this.EditlistOfFundName = [];
          }

          if (this.EditSourceList.length > 0) {
            this.EditselectedItemsSource = []
            this.EditlistOfsource = []
            this.EditlistOfsourceName= []
            for (let iCounter = 0; iCounter < result['content'].sourceTypeId.length; iCounter++) {

              let data = this.EditSourceList.filter(x => x.sourceTypeId == result['content'].sourceTypeId[iCounter].sourceTypeId)[0];
              this.EditselectedItemsSource.push({ "id": data.sourceTypeId, "itemName": data.sourceName })
              this.EditlistOfsource.push(data.sourceTypeId)
              this.EditlistOfsourceName.push(data.sourceName)
              // this.listOfSource.push(data.sourceTypeId)
            }
          }

          if (this.MonthList.length > 0) {
            let auditMonthList = [];
            if(result['content'].auditMonth != null && result['content'].auditMonth !=''){
              if (result['content'].auditMonth.includes(',')) {
                auditMonthList = result['content'].auditMonth.split(',');
              }
              else if (result['content'].auditMonth !== "") {
                auditMonthList.push(result['content'].auditMonth);
              }
            }

            if (auditMonthList.length > 0) {
              this.listOfMonth = [];
              this.selectedItemsMonth = [];

              for (let iCounter = 0; iCounter < auditMonthList.length; iCounter++) {

                let data = this.MonthList.filter(x => x.name == auditMonthList[iCounter])[0];
                if (data != undefined) {
                  this.selectedItemsMonth.push({ "id": data.id, "itemName": data.name })
                  this.listOfMonth.push(data.name)
                }
              }
            }
            // this.selectedItemsMonth = [];
            // let data = this.MonthList.filter(x => x.name == result['content'].auditMonth)[0];
            // if (data != undefined) {
            //   this.selectedItemsMonth.push({ "id": data.id, "itemName": data.name })
            // }
          }
        }
      });
  }

  cancelEdit(data) {
    data.isEdit = false;
    this.isRowEdited = false;
    this.selectedItemsMonth = [];
    this.EditselectedItemsSource = [];
    this.EditlistOffundIDs = [];
    this.listofContent = this.templistofContent;
  }

  saveContent(item) {
    this.saveSession();
    let userId= this.userID;
    let carrierId = this.listOfCarrierIDs.join(',');
    let carrierName=this.listOfCarrierName.join(',');
    let categoryId = this.listOfCategoryIDs.join(',');
    let CategoryName=this.listOfCategoryName.join(',');
    let editlistOffundID = this.EditlistOffundIDs.join(',');
    let fundName = this.EditlistOfFundName.join(',');
    let sourceID = this.EditlistOfsource.join(',');
    let sourceName = this.EditlistOfsourceName.join(',');
    let MonthList = this.listOfMonth.join(',');

    if (editlistOffundID == "" || carrierId == "" || categoryId == "" || item.ruleText == "" || item.effectiveDate == "") {
      if (carrierId == "") {
        this.showMessage("Please select carrier", 'warning', 'warning');
      }
      if (categoryId == "") {
        this.showMessage("Please select category", 'warning', 'warning');
      }
      if (item.ruleText == "") {
        this.showMessage("Please Enter ruleText", 'warning', 'warning');
      }
      if (item.effectiveDate == "") {
        this.showMessage("Please select effectivedate", 'warning', 'warning');
      }
      if (editlistOffundID == "") {
        this.showMessage("Please select fund", 'warning', 'warning');
      }
    }
    else {
      this.listOfMonth = [];

      if (item.internalNote == null) {
        item.internalNote = "";
      }
      if (item.ruleCitation == null) {
        item.ruleCitation = "";
      }
      if (item.subcategory == null) {
        item.subcategory = "";
      }

      let value = {
        contentID: item.contentID,
        fundID: editlistOffundID,
        effectiveDate: this.datePipe.transform(item.effectiveDate, 'yyyy-MM-dd hh:mm:ss'),
        ruleText: item.ruleText.trim(),
        ruleCitation: item.ruleCitation == "" ? item.ruleCitation : item.ruleCitation.trim(),
        internalNote: item.internalNote == "" ? item.internalNote : item.internalNote.trim(),
        subcategory: item.subcategory == "" ? item.subcategory : item.subcategory.trim(),
        carrierTypeID: carrierId,
        categoryID: categoryId,
        lastAudit: this.datePipe.transform(item.lastAudit, 'yyyy-MM-dd hh:mm:ss'),
        auditMonth: MonthList == '' ? null : MonthList,
        sourceTypeId: sourceID == "" ? "" : sourceID,
        userId:userId,
        carrierTypeName:carrierName,
        CategoryTypeName:CategoryName,
        sourceTypeName: sourceName == '' ? null : sourceName,
        fundName: fundName
      }

      let formData = new FormData();
    formData.append('contentID', item.contentID);
    formData.append('fundID', item.fundID);
    formData.append('effectiveDate', this.datePipe.transform(item.effectiveDate, 'yyyy-MM-dd hh:mm:ss'));
    formData.append('ruleText', item.ruleText==null?null:item.ruleText.trim());
    formData.append('ruleCitation', item.ruleCitation == "" ? item.ruleCitation : item.ruleCitation.trim());
    formData.append('internalNote', item.internalNote == "" ? item.internalNote : item.internalNote.trim(),);
    formData.append('subcategory', item.subcategory == "" ? item.subcategory : item.subcategory.trim());
    formData.append('carrierTypeID', carrierId);
    formData.append('categoryID', categoryId);
    formData.append('lastAudit', this.datePipe.transform(item.lastAudit, 'yyyy-MM-dd hh:mm:ss'));
    formData.append('auditMonth', MonthList);
    formData.append('sourceTypeId', sourceID == "" ? "" : sourceID);
    

      this.ContentService.ContentAddUpdate(this.tokenKey, formData).subscribe(
        result => {
          if (result['status'] == 'Success') {
            if (result['message'] != "") {
              this.showMessage(result['message'], 'Warning', 'warning')
            } else {
              if (item.contentID > 0) {
                this.showMessage('Content Update successfully', 'Success', 'success');
                //this.resetPageNoandSize();
                this.GetContent();
              }
              else
                this.showMessage('Content saved successfully', 'Success', 'success');
              this.router.navigate(['/admin/' + this.SelectedTool + '/content-list']);
            }
          }
          else {
            this.showMessage(result['status'], 'Error', 'error');
          }
        },
        error => {
          this.showMessage(error, 'Error', 'error');
        }
      );
      item.isEdit = false;
      this.isRowEdited = false;

    }
  }

  GetContent() {
    this.isloading = true;
    this.fundID = ' ';
    if (this.listOffundIDs.length > 0) {
      this.fundID = this.listOffundIDs.join(',')
    }
    this.stateId = ' ';
    if (this.listOfStateIds.length > 0) {
      this.stateId = this.listOfStateIds.join(',')
    }
    this.sourceTypeId = '';
    if (this.listOfsource.length > 0) {
      this.sourceTypeId = this.listOfsource.join(',')
    }

    this.categoryId = ' ';
    if (this.listOfCategoryIDsMain.length > 0) {
      this.categoryId = this.listOfCategoryIDsMain.join(',')
    }

    this.auditMonth = '';
    if (this.listOfMonth1.length > 0) {
      this.auditMonth = this.listOfMonth1.join(',')
    }

    if (this.Note == "null") this.Note = " ";
    this.trfaContentMaster.GetAllContent(this.tokenKey, this.PageNumber, this.PageSize, this.stateId, this.fundID, " ", this.categoryId, this.sourceTypeId, this.OrderBy, this.auditMonth).subscribe(
      data => {
        this.isloading = false;

        this.listofContent = [];
        debugger
        for (let i = 0; i < data['content'].length; i++) {
          let dataValue=data['content'][i];

          let effectiveDate = '';
          if (dataValue.effectiveDate != null && dataValue.effectiveDate != '') {
            let dateObj = new Date(dataValue.effectiveDate);
            let options: Intl.DateTimeFormatOptions = { year: 'numeric', month: 'long', day: 'numeric' };
            effectiveDate = dateObj.toLocaleDateString('en-US', options);
          }

          let lastAudit = '';
          if (dataValue.lastAudit != null && dataValue.lastAudit != '') {
            let dateObj = new Date(dataValue.lastAudit);
            let options: Intl.DateTimeFormatOptions = { year: 'numeric', month: 'long', day: 'numeric' };
            lastAudit = dateObj.toLocaleDateString('en-US', options);
          }

          this.listofContent.push({
            auditMonth: dataValue.auditMonth,
            carrierName: dataValue.carrierName,
            categoryName: dataValue.categoryName,
            contentID: dataValue.contentID,
            effectiveDate: effectiveDate,
            effectiveDates: dataValue.effectiveDates,
            fundID: dataValue.fundID,
            fundName: dataValue.fundName,
            fundTypeName: dataValue.fundTypeName,
            internalNote: dataValue.internalNote,
            isFavorite: dataValue.isFavorite,
            lastAudit: lastAudit,
            note: dataValue.note,
            ruleCitation: dataValue.ruleCitation,
            ruleText: dataValue.ruleText,
            ruleTextupdated: dataValue.ruleTextupdated,
            sourceName: dataValue.sourceName,
            stateID: dataValue.stateID,
            stateName: dataValue.stateName,
            subcategory: dataValue.subcategory,
            totalRecordCount: dataValue.totalRecordCount
          });
        }
        
        this.listofContent.forEach(element => {
          element['isEdit'] = false;
        });

        if (this.listofContent != null) {
          if (this.listofContent.length == 0)
            this.isRecord = true;
          else {
            this.isRecord = false;
            this.totalRecordCount = this.listofContent[0].totalRecordCount;
          }
        }
        else
          this.isRecord = true;
      },
      error => { this.stateCode = error }
    );
  }

  onSelectCarrier($event) {
    this.listOfCarrierIDs = this.removeElemetninArray(this.listOfCarrierIDs, $event.id)
    this.listOfCarrierName= this.removeElemetninArray(this.listOfCarrierName, $event.itemName)
    this.listOfCarrierIDs.push($event.id);
    this.listOfCarrierName.push($event.itemName);
  }

  onDeSelectCarrier($event) {
    if (this.listOfCarrierIDs != null) {
      if (this.listOfCarrierIDs.length > 0) {
        this.listOfCarrierIDs = this.removeElemetninArray(this.listOfCarrierIDs, $event.id)
      }
    }

    if (this.listOfCarrierName != null) {
      if (this.listOfCarrierName.length > 0) {
        this.listOfCarrierName = this.removeElemetninArray(this.listOfCarrierName, $event.itemName)
      }
    }
  }

  onSelectAllCarrier($event) {
    for (let iCounter = 0; iCounter < $event.length; iCounter++) {
      if (this.listOfCarrierIDs != null) {
        if (this.listOfCarrierIDs.length > 0) {
          this.listOfCarrierIDs = this.removeElemetninArray(this.listOfCarrierIDs, $event[iCounter].id)
          this.listOfCarrierIDs.push($event[iCounter].id);
        }
        else {
          this.listOfCarrierIDs.push($event[iCounter].id);
        }
      }

      if (this.listOfCarrierName != null) {
        if (this.listOfCarrierName.length > 0) {
          this.listOfCarrierName = this.removeElemetninArray(this.listOfCarrierName, $event[iCounter].itemName)
          this.listOfCarrierName.push($event[iCounter].itemName);
        }
        else {
          this.listOfCarrierName.push($event[iCounter].itemName);
        }
      }    
    }
  }

  onDeSelectAllCarrier($event) {
    this.listOfCarrierIDs = [];
    this.listOfCarrierName=[];
  }
  onSelectCategory($event) {
    this.listOfCategoryIDs = []
    this.listOfCategoryIDs = this.removeElemetninArray(this.listOfCategoryIDs, $event.id)
    this.listOfCategoryIDs.push($event.id);

    this.listOfCategoryName = []
    this.listOfCategoryName=this.removeElemetninArray(this.listOfCategoryName, $event.itemName)
    this.listOfCategoryName.push($event.itemName);
  }

  onDeSelectCategory($event) {
    if (this.listOfCategoryIDs != null) {
      if (this.listOfCategoryIDs.length > 0) {
        this.listOfCategoryIDs = this.removeElemetninArray(this.listOfCategoryIDs, $event.id)
      }
    }

    if (this.listOfCategoryName != null) {
      if (this.listOfCategoryName.length > 0) {
        this.listOfCategoryName = this.removeElemetninArray(this.listOfCategoryName, $event.itemName)
      }
    }
  }

  onSelectAllCategory($event) {
    this.listOfCategoryIDs = []
    this.listOfCategoryName=[];
    for (let iCounter = 0; iCounter < $event.length; iCounter++) {
      if (this.listOfCategoryIDs != null) {
        if (this.listOfCategoryIDs.length > 0) {
          this.listOfCategoryIDs = this.removeElemetninArray(this.listOfCategoryIDs, $event[iCounter].id)
          this.listOfCategoryIDs.push($event[iCounter].id);
        }
        else {
          this.listOfCategoryIDs.push($event[iCounter].id);
        }
      }

      if (this.listOfCategoryName != null) {
        if (this.listOfCategoryName.length > 0) {
          this.listOfCategoryName = this.removeElemetninArray(this.listOfCategoryName, $event[iCounter].itemName)
          this.listOfCategoryName.push($event[iCounter].itemName);
        }
        else {
          this.listOfCategoryName.push($event[iCounter].itemName);
        }
      }
    }
  }

  onDeSelectAllCategory($event) {
    this.listOfCategoryIDs = [];
    this.listOfCategoryName=[];
  }

  keywordFilters($event) {
    this.PageNumber = 1;
    if ($event.target.value != "" && $event.target.value != null)
      this.Note = $event.target.value;
    else
      this.Note = 'null';
    this.resetPageNoandSize();
    this.GetContent();
  }

  sort(sortBy: string) {
    if (!this.isAscending) {
      this.isAscending = true;
      this.OrderBy = sortBy + ' DESC';
      // this.upDowEffectiveDateCSS = '';
      // this.upDowLastAuditCSS = '';
      // this.upDowRuleCitationCSS = '';
      if (sortBy === "RuleText") {
        this.upDowRuleTextCSS = 'fa fa-arrow-down';
        this.upDowStateNameCSS = '';
        this.upDowFundNameCSS = '';
        this.upDowCategoryNameCSS = '';
        this.upDowSourceNameCSS = '';
      }
      else if (sortBy === "stateName") {
        this.upDowRuleTextCSS = '';
        this.upDowStateNameCSS = 'fa fa-arrow-down';
        this.upDowFundNameCSS = '';
        this.upDowCategoryNameCSS = '';
        this.upDowSourceNameCSS = '';
      }
      else if (sortBy === "FundName") {
        this.upDowRuleTextCSS = '';
        this.upDowStateNameCSS = '';
        this.upDowFundNameCSS = 'fa fa-arrow-down';
        this.upDowCategoryNameCSS = '';
        this.upDowSourceNameCSS = '';
      }
      else if (sortBy === "CategoryName") {
        this.upDowRuleTextCSS = '';
        this.upDowStateNameCSS = '';
        this.upDowFundNameCSS = '';
        this.upDowCategoryNameCSS = 'fa fa-arrow-down';
        this.upDowSourceNameCSS = '';
      }
      else if(sortBy === "sourceName")
      this.upDowRuleTextCSS = '';
        this.upDowStateNameCSS = '';
        this.upDowFundNameCSS = '';
        this.upDowCategoryNameCSS = '';
        this.upDowSourceNameCSS = 'fa fa-arrow-down';

    }
    else {
      this.isAscending = false;
      this.OrderBy = sortBy;

      if (sortBy === "RuleText") {
        this.upDowRuleTextCSS = 'fa fa-arrow-up';
        this.upDowStateNameCSS = '';
        this.upDowFundNameCSS = '';
        this.upDowCategoryNameCSS = '';
        this.upDowSourceNameCSS = '';
      }
      else if (sortBy === "stateName") {
        this.upDowRuleTextCSS = '';
        this.upDowStateNameCSS = 'fa fa-arrow-down';
        this.upDowFundNameCSS = '';
        this.upDowCategoryNameCSS = '';
        this.upDowSourceNameCSS = '';
      }
      else if (sortBy === "FundName") {
        this.upDowRuleTextCSS = '';
        this.upDowStateNameCSS = '';
        this.upDowFundNameCSS = 'fa fa-arrow-up';
        this.upDowCategoryNameCSS = '';
        this.upDowSourceNameCSS = '';
      }
      else if (sortBy === "CategoryName") {
        this.upDowRuleTextCSS = '';
        this.upDowStateNameCSS = '';
        this.upDowFundNameCSS = '';
        this.upDowCategoryNameCSS = 'fa fa-arrow-up';
        this.upDowSourceNameCSS = '';
      }
      else if (sortBy === "sourceName"){
        this.upDowRuleTextCSS = '';
        this.upDowStateNameCSS = '';
        this.upDowFundNameCSS = '';
        this.upDowCategoryNameCSS = '';
        this.upDowSourceNameCSS = 'fa fa-arrow-up';
      }

    }
    this.resetPageNoandSize();
    this.GetContent();
  }

  addContent() {
    let navigation = '/admin/' + this.SelectedTool + '/content/manage';
    this.router.navigate([navigation]);
  }

  ContentContent(ContentId) {
    let navigation = '/admin/' + this.SelectedTool + '/content/manage/' + ContentId;
    this.router.navigate([navigation]);
  }

  setDeleteData(id) {
    this.ContentId = id;
  }

  deleteContent(ContentId) {
    this.isloading = true;
    this.trfaContentMaster.DeleteContent(this.tokenKey, ContentId).subscribe(
      data => {
        this.isloading = false;
        if (data['status'] == 'Success') {
          // if (data['message'] != "")
          //   this.showMessage(data['message'], 'Warning', 'warning')
          // else
          this.showMessage("Deleted successfully", 'Success', 'success')
        }
        this.resetPageNoandSize();
        this.GetContent();
      },
      error => { this.stateCode = error }
    );
  }

  showMessage(msg: string, msgTitle: string, msgType: string) {
    if (msgType == 'success') {
      setTimeout(() => this.toastr.success(msg, msgTitle));
    }
    else if (msgType == 'error') {
      setTimeout(() => this.toastr.error(msg, msgTitle));
    }
    else if (msgType == 'warning') {
      setTimeout(() => this.toastr.warning(msg, msgTitle));
    }
  }

  removeElemetninArray(array, element) {
    return array.filter(e => e !== element);
  }

  onSelectState($event) {
    this.listOfStateIds = this.removeElemetninArray(this.listOfStateIds, $event.id)
    this.listOfStateIds.push($event.id);
    this.stateId = ' ';
    if (this.listOfStateIds.length > 0) {
      this.stateId = this.listOfStateIds.join(',')
    }
    this.getFund();
    this.resetPageNoandSize();
    this.GetContent();
  }

  onDeSelectState($event) {
    if (this.listOfStateIds != null) {
      if (this.listOfStateIds.length > 0) {
        this.listOfStateIds = this.removeElemetninArray(this.listOfStateIds, $event.id)
      }
    }
    if (this.listOfStateIds.length > 0) {
      this.stateId = this.listOfStateIds.join(',')
    }
    this.getFund();
    this.resetPageNoandSize();
    this.GetContent();
  }

  onSelectAll($event) {
    for (let iCounter = 0; iCounter < $event.length; iCounter++) {
      if (this.listOfStateIds != null) {
        if (this.listOfStateIds.length > 0) {
          this.listOfStateIds = this.removeElemetninArray(this.listOfStateIds, $event[iCounter].id)
          this.listOfStateIds.push($event[iCounter].id);
        }
        else {
          this.listOfStateIds.push($event[iCounter].id);
        }
      }
    }
    if (this.listOfStateIds.length > 0) {
      this.stateId = this.listOfStateIds.join(',')
    }
    this.getFund();
    this.resetPageNoandSize();
    this.GetContent();
  }

  onDeSelectAllState($event) {
    this.listOfStateIds = [];
    this.getFund();
    this.resetPageNoandSize();
    this.GetContent();
  }


  onSelectFund($event) {
    this.listOffundIDs = this.removeElemetninArray(this.listOffundIDs, $event.id)
    this.listOffundIDs.push($event.id);
    // this.stateId = ' ';
    // if (this.listOffundIDs.length > 0) {
    //   this.stateId = this.listOffundIDs.join(',')
    // }
    this.resetPageNoandSize();
    this.GetContent();
  }

  onDeSelectFund($event) {
    if (this.listOffundIDs != null) {
      if (this.listOffundIDs.length > 0) {
        this.listOffundIDs = this.removeElemetninArray(this.listOffundIDs, $event.id)
      }
    }
    if (this.listOffundIDs.length > 0) {
      this.stateId = this.listOffundIDs.join(',')
    }
    this.resetPageNoandSize();
    this.GetContent();
  }

  onSelectAllFund($event) {
    for (let iCounter = 0; iCounter < $event.length; iCounter++) {
      if (this.listOffundIDs != null) {
        if (this.listOffundIDs.length > 0) {
          this.listOffundIDs = this.removeElemetninArray(this.listOffundIDs, $event[iCounter].id)
          this.listOffundIDs.push($event[iCounter].id);
        }
        else {
          this.listOffundIDs.push($event[iCounter].id);
        }
      }
    }
    this.resetPageNoandSize();
    this.GetContent();
  }

  onDeSelectAllFund($event) {
    this.listOffundIDs = [];
    this.resetPageNoandSize();
    this.GetContent();
  }

  replaceCommaLine(data) {
    if (data != '' && data != null) {
      let dataToArray = data.split(',').map(item => item.trim());
      let list = data.split(",").join("<br />")
      // return dataToArray.join("\n");
      return list
    }

  }
  getState() {
    this.stateService.GetStateMaster(this.tokenKey, this.module).subscribe(
      data => {
        this.stateList = data['state'];
        this.dropdownListState = [];
        if (this.stateList.length > 0) {
          this.stateList.forEach(x => {
            this.dropdownListState.push({ "id": x.stateId, "itemName": x.name })
          });
        }
      },
      error => { }
    );
  }

  getSource() {
    this.trfaSourceMaster.GetSource(this.tokenKey).subscribe(
      data => {
        this.SourceList = data['source'];
        this.dropdownListSource = [];

        this.EditSourceList = data['source'];
        this.EditdropdownListSource = [];

        if (this.SourceList.length > 0) {
          this.SourceList.forEach(x => {
            this.dropdownListSource.push({ "id": x.sourceTypeId, "itemName": x.sourceName })
            this.EditdropdownListSource.push({ "id": x.sourceTypeId, "itemName": x.sourceName })
          });
        }
      },
      error => { }
    );
  }

  onSelectSource($event) {
    this.listOfsource = this.removeElemetninArray(this.listOfsource, $event.id)
    this.listOfsource.push($event.id);
    this.resetPageNoandSize();
    this.GetContent();
  }

  onDeSelectSource($event) {
    if (this.listOfsource != null) {
      if (this.listOfsource.length > 0) {
        this.listOfsource = this.removeElemetninArray(this.listOfsource, $event.id)
      }
    }
    if (this.listOfsource.length > 0) {
      this.sourceTypeId = this.listOfsource.join(',')
    }
    this.resetPageNoandSize();
    this.GetContent();
  }

  onDeSelectAllSource($event) {
    this.listOfsource = [];
    this.resetPageNoandSize();
    this.GetContent();
  }

  onSelectAllSource($event) {
    for (let iCounter = 0; iCounter < $event.length; iCounter++) {
      if (this.listOfsource != null) {
        if (this.listOfsource.length > 0) {
          this.listOfsource = this.removeElemetninArray(this.listOfsource, $event[iCounter].id)
          this.listOfsource.push($event[iCounter].id);
        }
        else {
          this.listOfsource.push($event[iCounter].id);
        }
      }
    }
    this.resetPageNoandSize();
    this.GetContent();
  }

  onSelectEditFund($event) {
    this.EditlistOffundIDs = [];
    this.EditlistOfFundName = [];
    //this.EditlistOffundIDs = this.removeElemetninArray(this.EditlistOffundIDs, $event.id)
    this.EditlistOffundIDs.push($event.id);
    this.EditlistOfFundName.push($event.itemName);
  }

  onDeSelectEditFund($event) {
    if (this.EditlistOffundIDs != null) {
      if (this.EditlistOffundIDs.length > 0) {
        this.EditlistOffundIDs = this.removeElemetninArray(this.EditlistOffundIDs, $event.id)
      }
    }

    if (this.EditlistOfFundName != null) {
      if (this.EditlistOfFundName.length > 0) {
        this.EditlistOfFundName = this.removeElemetninArray(this.EditlistOfFundName, $event.itemName)
      }
    }
  }

  onSelectAllEditFund($event) {
    for (let iCounter = 0; iCounter < $event.length; iCounter++) {
      if (this.EditlistOffundIDs != null) {
        if (this.EditlistOffundIDs.length > 0) {
          this.EditlistOffundIDs = this.removeElemetninArray(this.EditlistOffundIDs, $event[iCounter].id)
          this.EditlistOffundIDs.push($event[iCounter].id);
        }
        else {
          this.EditlistOffundIDs.push($event[iCounter].id);
        }
      }

      if (this.EditlistOfFundName != null) {
        if (this.EditlistOfFundName.length > 0) {
          this.EditlistOfFundName = this.removeElemetninArray(this.EditlistOfFundName, $event[iCounter].itemName)
          this.EditlistOfFundName.push($event[iCounter].itemName);
        }
        else {
          this.EditlistOfFundName.push($event[iCounter].itemName);
        }
      }
    }
  }

  onDeSelectAllEditFund($event) {
    this.EditlistOffundIDs = [];
    this.EditlistOfFundName = [];
  }

  onSelectEditSource($event) {
    this.EditlistOfsource = this.removeElemetninArray(this.EditlistOfsource, $event.id)
    this.EditlistOfsource.push($event.id);
    this.EditlistOfsourceName = this.removeElemetninArray(this.EditlistOfsourceName, $event.itemName)
    this.EditlistOfsourceName.push($event.itemName);
  }

  onDeSelectEditSource($event) {
    if (this.EditlistOfsource != null) {
      if (this.EditlistOfsource.length > 0) {
        this.EditlistOfsource = this.removeElemetninArray(this.EditlistOfsource, $event.id)
      }
    }
    if (this.EditlistOfsource.length > 0) {
      this.EditsourceTypeId = this.EditlistOfsource.join(',')
    }

    if (this.EditlistOfsourceName != null) {
      if (this.EditlistOfsourceName.length > 0) {
        this.EditlistOfsourceName = this.removeElemetninArray(this.EditlistOfsourceName, $event.itemName)
      }
    }
  }

  onDeSelectAllEditSource($event) {
    this.EditlistOfsource = [];
    this.EditlistOfsourceName=[];
  }

  onSelectAllEditSource($event) {
    for (let iCounter = 0; iCounter < $event.length; iCounter++) {
      if (this.EditlistOfsource != null) {
        if (this.EditlistOfsource.length > 0) {
          this.EditlistOfsource = this.removeElemetninArray(this.EditlistOfsource, $event[iCounter].id)
          this.EditlistOfsource.push($event[iCounter].id);
        }
        else {
          this.EditlistOfsource.push($event[iCounter].id);
        }
      }

      if (this.EditlistOfsourceName != null) {
        if (this.EditlistOfsourceName.length > 0) {
          this.EditlistOfsourceName = this.removeElemetninArray(this.EditlistOfsourceName, $event[iCounter].itemName)
          this.EditlistOfsourceName.push($event[iCounter].itemName);
        }
        else {
          this.EditlistOfsourceName.push($event[iCounter].itemName);
        }
      }
    }
  }

  getMonths() {
    this.MonthList = this.trfaMonthsService.getMonths();
    this.dropdownMonthFilter = [];
    this.selectedItemsMonth = [];
    this.MonthList.forEach(x => {
      this.dropdownMonthFilter.push({ "id": x.id, "itemName": x.name })
    });
  }

  onDeSelectMonthAll($event) {
    this.selectedItemsMonth = [];
    this.listOfMonth = [];
  }

  onSelectMonth($event) {
    this.listOfMonth = this.removeElemetninArray(this.listOfMonth, $event.itemName)
    this.listOfMonth.push($event.itemName);
    // this.ContentMasterForm.patchValue({
    //   auditMonth: event.itemName
    // });
  }

  onDeSelectMonth($event) {
    if (this.listOfMonth != null) {
      if (this.listOfMonth.length > 0) {
        this.listOfMonth = this.removeElemetninArray(this.listOfMonth, $event.itemName)
      }
    }
  }

  onSelectAllMonth($event) {
    for (let iCounter = 0; iCounter < $event.length; iCounter++) {
      if (this.listOfMonth != null) {
        if (this.listOfMonth.length > 0) {
          this.listOfMonth = this.removeElemetninArray(this.listOfMonth, $event[iCounter].itemName)
          this.listOfMonth.push($event[iCounter].itemName);
        }
        else {
          this.listOfMonth.push($event[iCounter].itemName);
        }
      }
    }
  }

  onSelectCategoryMain($event) {
    this.listOfCategoryIDsMain = this.removeElemetninArray(this.listOfCategoryIDsMain, $event.id)
    this.listOfCategoryIDsMain.push($event.id);
    this.resetPageNoandSize();
    this.GetContent();
  }

  onDeSelectCategoryMain($event) {
    if (this.listOfCategoryIDsMain != null) {
      if (this.listOfCategoryIDsMain.length > 0) {
        this.listOfCategoryIDsMain = this.removeElemetninArray(this.listOfCategoryIDsMain, $event.id)
      }
    }
    this.resetPageNoandSize();
    this.GetContent();
  }

  onSelectAllCategoryMain($event) {
    this.listOfCategoryIDsMain = []
    for (let iCounter = 0; iCounter < $event.length; iCounter++) {
      if (this.listOfCategoryIDsMain != null) {
        if (this.listOfCategoryIDsMain.length > 0) {
          this.listOfCategoryIDsMain = this.removeElemetninArray(this.listOfCategoryIDsMain, $event[iCounter].id)
          this.listOfCategoryIDsMain.push($event[iCounter].id);
        }
        else {
          this.listOfCategoryIDsMain.push($event[iCounter].id);
        }
      }
    }
    this.resetPageNoandSize();
    this.GetContent();
  }

  onDeSelectAllCategoryMain($event) {

    this.listOfCategoryIDsMain = [];
    this.resetPageNoandSize();
    this.GetContent();
  }

  resetPageNoandSize() {
    this.PageNumber = 1;
    this.PageSize = 10;
  }

  getMonths1() {
    this.MonthList1 = this.trfaMonthsService.getMonths();
    this.dropdownMonthFilter1 = [];
    this.selectedItemsMonth1 = [];
    this.MonthList1.forEach(x => {
      this.dropdownMonthFilter1.push({ "id": x.id, "itemName": x.name })
    });
  }

  onSelectMonth1($event) {
    //this.listOfMonth1 = this.removeElemetninArray(this.listOfMonth1, $event.itemName)
    this.listOfMonth1=[];
    this.listOfMonth1.push($event.itemName);
    this.resetPageNoandSize();
    this.GetContent();
  }

  onDeSelectMonth1($event) {
    // if (this.listOfMonth1 != null) {
    //   if (this.listOfMonth1.length > 0) {
    //     this.listOfMonth1 = this.removeElemetninArray(this.listOfMonth1, $event.itemName)
    //   }
    // }
    this.listOfMonth1=[];
    this.resetPageNoandSize();
    this.GetContent();
  }

  onSelectAllMonth1($event) {
    for (let iCounter = 0; iCounter < $event.length; iCounter++) {
      if (this.listOfMonth1 != null) {
        if (this.listOfMonth1.length > 0) {
          this.listOfMonth1 = this.removeElemetninArray(this.listOfMonth1, $event[iCounter].itemName)
          this.listOfMonth1.push($event[iCounter].itemName);
        }
        else {
          this.listOfMonth1.push($event[iCounter].itemName);
        }
      }
    }
    this.resetPageNoandSize();
    this.GetContent();

  }

  onDeSelectMonthAll1($event) {
    this.listOfMonth1 = [];
    this.resetPageNoandSize();
    this.GetContent();
  }

  saveSession() {
    sessionStorage.setItem('ContentSaveFilterPageNo', this.PageNumber.toString());
    sessionStorage.setItem('ContentSaveFilterOrderBy', this.OrderBy);
    sessionStorage.setItem('ContentSaveFilterIsAscending', this.isAscending ? 'true' : 'false');
    sessionStorage.setItem('ContentSaveFilterFundId', JSON.stringify(this.listOffundIDs));
    sessionStorage.setItem('ContentSaveFilterStateId', JSON.stringify(this.listOfStateIds));
    sessionStorage.setItem('ContentSaveFilterCategoryId', JSON.stringify(this.listOfCategoryIDsMain));
    sessionStorage.setItem('ContentSaveFilterlistOfMonth', JSON.stringify(this.listOfMonth1));
  }

  clearSession() {
    sessionStorage.removeItem('ContentSaveFilterPageNo');
    sessionStorage.removeItem('ContentSaveFilterOrderBy');
    sessionStorage.removeItem('ContentSaveFilterIsAscending');
    sessionStorage.removeItem('ContentSaveFilterFundId');
    sessionStorage.removeItem('ContentSaveFilterStateId');
    sessionStorage.removeItem('ContentSaveFilterCategoryId');
    sessionStorage.removeItem('ContentSaveFilterlistOfMonth');
  }

  setSaveFilter() {
    if (sessionStorage.getItem('ContentSaveFilterPageNo') != null && sessionStorage.getItem('ContentSaveFilterPageNo') != '' && sessionStorage.getItem('ContentSaveFilterPageNo') != 'undefined') {
      this.PageNumber = +sessionStorage.getItem('ContentSaveFilterPageNo');
    }
    if (sessionStorage.getItem('ContentSaveFilterOrderBy') != null && sessionStorage.getItem('ContentSaveFilterOrderBy') != '' && sessionStorage.getItem('ContentSaveFilterOrderBy') != 'undefined') {
      this.OrderBy = sessionStorage.getItem('ContentSaveFilterOrderBy');
    }
    if (sessionStorage.getItem('ContentSaveFilterIsAscending') != null && sessionStorage.getItem('ContentSaveFilterIsAscending') != '' && sessionStorage.getItem('ContentSaveFilterIsAscending') != 'undefined') {
      this.isAscending = sessionStorage.getItem('ContentSaveFilterIsAscending') == 'false' ? false : true;
      if (this.isAscending) {
        this.ContentSessionSort(this.OrderBy.replace('DESC', '').trim());
      } else {
        this.ContentSessionSort(this.OrderBy.replace('DESC', '').trim());
      }
    }
    if (sessionStorage.getItem('ContentSaveFilterFundId') != null && sessionStorage.getItem('ContentSaveFilterFundId') != '' && sessionStorage.getItem('ContentSaveFilterFundId') != 'undefined') {
      this.listOffundIDs = JSON.parse(sessionStorage.getItem('ContentSaveFilterFundId'));
    }
    if (sessionStorage.getItem('ContentSaveFilterStateId') != null && sessionStorage.getItem('ContentSaveFilterStateId') != '' && sessionStorage.getItem('ContentSaveFilterStateId') != 'undefined') {
      this.listOfStateIds = JSON.parse(sessionStorage.getItem('ContentSaveFilterStateId'));
    }
    if (sessionStorage.getItem('ContentSaveFilterCategoryId') != null && sessionStorage.getItem('ContentSaveFilterCategoryId') != '' && sessionStorage.getItem('ContentSaveFilterCategoryId') != 'undefined') {
      this.listOfCategoryIDsMain = JSON.parse(sessionStorage.getItem('ContentSaveFilterCategoryId'));
    }
    if (sessionStorage.getItem('ContentSaveFilterlistOfMonth') != null && sessionStorage.getItem('ContentSaveFilterlistOfMonth') != '' && sessionStorage.getItem('ContentSaveFilterlistOfMonth') != 'undefined') {
      this.listOfMonth1 = JSON.parse(sessionStorage.getItem('ContentSaveFilterlistOfMonth'));
    }
    
    this.clearSession();
  }

  ContentSessionSort(sortBy: string) {
    if (!this.isAscending) {
      this.isAscending = true;
      this.OrderBy = sortBy + ' DESC';
      // this.upDowEffectiveDateCSS = '';
      // this.upDowLastAuditCSS = '';
      // this.upDowRuleCitationCSS = '';
      if (sortBy === "RuleText") {
        this.upDowRuleTextCSS = 'fa fa-arrow-down';
        this.upDowStateNameCSS = '';
        this.upDowFundNameCSS = '';
        this.upDowCategoryNameCSS = '';
        this.upDowSourceNameCSS = '';
      }
      else if (sortBy === "stateName") {
        this.upDowRuleTextCSS = '';
        this.upDowStateNameCSS = 'fa fa-arrow-down';
        this.upDowFundNameCSS = '';
        this.upDowCategoryNameCSS = '';
        this.upDowSourceNameCSS = '';
      }
      else if (sortBy === "FundName") {
        this.upDowRuleTextCSS = '';
        this.upDowStateNameCSS = '';
        this.upDowFundNameCSS = 'fa fa-arrow-down';
        this.upDowCategoryNameCSS = '';
        this.upDowSourceNameCSS = '';
      }
      else if (sortBy === "CategoryName") {
        this.upDowRuleTextCSS = '';
        this.upDowStateNameCSS = '';
        this.upDowFundNameCSS = '';
        this.upDowCategoryNameCSS = 'fa fa-arrow-down';
        this.upDowSourceNameCSS = '';
      }
      else if(sortBy === "sourceName")
      this.upDowRuleTextCSS = '';
        this.upDowStateNameCSS = '';
        this.upDowFundNameCSS = '';
        this.upDowCategoryNameCSS = '';
        this.upDowSourceNameCSS = 'fa fa-arrow-down';

    }
    else {
      this.isAscending = false;
      this.OrderBy = sortBy;

      if (sortBy === "RuleText") {
        this.upDowRuleTextCSS = 'fa fa-arrow-up';
        this.upDowStateNameCSS = '';
        this.upDowFundNameCSS = '';
        this.upDowCategoryNameCSS = '';
        this.upDowSourceNameCSS = '';
      }
      else if (sortBy === "stateName") {
        this.upDowRuleTextCSS = '';
        this.upDowStateNameCSS = 'fa fa-arrow-down';
        this.upDowFundNameCSS = '';
        this.upDowCategoryNameCSS = '';
        this.upDowSourceNameCSS = '';
      }
      else if (sortBy === "FundName") {
        this.upDowRuleTextCSS = '';
        this.upDowStateNameCSS = '';
        this.upDowFundNameCSS = 'fa fa-arrow-up';
        this.upDowCategoryNameCSS = '';
        this.upDowSourceNameCSS = '';
      }
      else if (sortBy === "CategoryName") {
        this.upDowRuleTextCSS = '';
        this.upDowStateNameCSS = '';
        this.upDowFundNameCSS = '';
        this.upDowCategoryNameCSS = 'fa fa-arrow-up';
        this.upDowSourceNameCSS = '';
      }
      else if (sortBy === "sourceName"){
        this.upDowRuleTextCSS = '';
        this.upDowStateNameCSS = '';
        this.upDowFundNameCSS = '';
        this.upDowCategoryNameCSS = '';
        this.upDowSourceNameCSS = 'fa fa-arrow-up';
      }

    }
    this.resetPageNoandSize();
    this.GetContent();
  }

  onFilterSelectAllState($event){
    for (let iCounter = 0; iCounter < $event.length; iCounter++) {
      if (this.listOfStateIds != null) {
        if (this.listOfStateIds.length > 0) {
          this.listOfStateIds = this.removeElemetninArray(this.listOfStateIds, $event[iCounter].id)
          this.listOfStateIds.push($event[iCounter].id);
        }
        else {
          this.listOfStateIds.push($event[iCounter].id);
        }
      }
    }
    if (this.listOfStateIds.length > 0) {
      this.stateId = this.listOfStateIds.join(',')
    }
    this.getFund();
    this.resetPageNoandSize();
    this.GetContent();
  }

  onFilterDeSelectAllState($event){
    for (let iCounter = 0; iCounter < $event.length; iCounter++) {
      if (this.listOfStateIds != null) {
        if (this.listOfStateIds.length > 0) {
          this.listOfStateIds = this.removeElemetninArray(this.listOfStateIds, $event[iCounter].id)
        }
      }
    }
    if (this.listOfStateIds.length > 0) {
      this.stateId = this.listOfStateIds.join(',')
    }
    this.getFund();
    this.resetPageNoandSize();
    this.GetContent();
  }

  onFilterSelectAllFund($event){
    for (let iCounter = 0; iCounter < $event.length; iCounter++) {
      if (this.listOffundIDs != null) {
        if (this.listOffundIDs.length > 0) {
          this.listOffundIDs = this.removeElemetninArray(this.listOffundIDs, $event[iCounter].id)
          this.listOffundIDs.push($event[iCounter].id);
        }
        else {
          this.listOffundIDs.push($event[iCounter].id);
        }
      }
    }
    this.resetPageNoandSize();
    this.GetContent();
  }

  onFilterDeSelectAllFund($event){
    for (let iCounter = 0; iCounter < $event.length; iCounter++) {
      if (this.listOffundIDs != null) {
        if (this.listOffundIDs.length > 0) {
          this.listOffundIDs = this.removeElemetninArray(this.listOffundIDs, $event[iCounter].id)
        }
      }
    }
    this.resetPageNoandSize();
    this.GetContent();
  }

  onFilterSelectAllCategory($event){
    this.listOfCategoryIDsMain = []
    for (let iCounter = 0; iCounter < $event.length; iCounter++) {
      if (this.listOfCategoryIDsMain != null) {
        if (this.listOfCategoryIDsMain.length > 0) {
          this.listOfCategoryIDsMain = this.removeElemetninArray(this.listOfCategoryIDsMain, $event[iCounter].id)
          this.listOfCategoryIDsMain.push($event[iCounter].id);
        }
        else {
          this.listOfCategoryIDsMain.push($event[iCounter].id);
        }
      }
    }
    this.resetPageNoandSize();
    this.GetContent();
  }

  onFilterDeSelectAllCategory($event){
    this.listOfCategoryIDsMain = []
    for (let iCounter = 0; iCounter < $event.length; iCounter++) {
      if (this.listOfCategoryIDsMain != null) {
        if (this.listOfCategoryIDsMain.length > 0) {
          this.listOfCategoryIDsMain = this.removeElemetninArray(this.listOfCategoryIDsMain, $event[iCounter].id)
        }
      }
    }
    this.resetPageNoandSize();
    this.GetContent();
  }

  onFilterSelectAllMonth($event){
    for (let iCounter = 0; iCounter < $event.length; iCounter++) {
      if (this.listOfMonth1 != null) {
        if (this.listOfMonth1.length > 0) {
          this.listOfMonth1 = this.removeElemetninArray(this.listOfMonth1, $event[iCounter].itemName)
          this.listOfMonth1.push($event[iCounter].itemName);
        }
        else {
          this.listOfMonth1.push($event[iCounter].itemName);
        }
      }
    }
    this.resetPageNoandSize();
    this.GetContent();
  }

  onFilterDeSelectAllMonth($event){
    for (let iCounter = 0; iCounter < $event.length; iCounter++) {
      if (this.listOfMonth1 != null) {
        if (this.listOfMonth1.length > 0) {
          this.listOfMonth1 = this.removeElemetninArray(this.listOfMonth1, $event[iCounter].itemName)
        }
      }
    }
    this.resetPageNoandSize();
    this.GetContent();
  }

  onFilterSelectAllSource($event){
    for (let iCounter = 0; iCounter < $event.length; iCounter++) {
      if (this.listOfsource != null) {
        if (this.listOfsource.length > 0) {
          this.listOfsource = this.removeElemetninArray(this.listOfsource, $event[iCounter].id)
          this.listOfsource.push($event[iCounter].id);
        }
        else {
          this.listOfsource.push($event[iCounter].id);
        }
      }
    }
    this.resetPageNoandSize();
    this.GetContent();
  }

  onFilterDeSelectAllSource($event){
    for (let iCounter = 0; iCounter < $event.length; iCounter++) {
      if (this.listOfsource != null) {
        if (this.listOfsource.length > 0) {
          this.listOfsource = this.removeElemetninArray(this.listOfsource, $event[iCounter].id)
        }
      }
    }
    this.resetPageNoandSize();
    this.GetContent();
  }

  toggleMenu(index: number) {
    this.openMenuIndex = this.openMenuIndex === index ? -1 : index; // Toggle the menu
  }

  @HostListener('document:click', ['$event'])
  onDocumentClick(event: MouseEvent) {
    const target = event.target as HTMLElement;
    if (!target.closest('.kebab-menu')) {
      this.openMenuIndex = null;
    }
  }

}


