import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { throwError as observableThrowError, Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class ReportService {

  AppUrl: string = "";
  constructor(private _http: HttpClient, @Inject('BASE_URL') baseUrl: string) {
    this.setAPIUrl();
  }

  setAPIUrl() {
    if (sessionStorage.getItem("BaseURL") != null && sessionStorage.getItem("BaseURL") != "") {
      this.AppUrl = sessionStorage.getItem("BaseURL") || '';
    }
  }
  ComplianceReport(TokenKey: any, filterModel): Observable<any> {
    this.setAPIUrl();
    let data = 'api/Report/ComplianceReport';
    const httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Token': TokenKey }) };
    return this._http.post<any>(this.AppUrl + data, filterModel, httpOptions)
      .pipe(catchError(this.handleError));
  }

  BriefingReport(TokenKey: any, filterModel): Observable<any> {
    this.setAPIUrl();
    let data = 'api/Report/BriefingReport';
    const httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Token': TokenKey }) };
    return this._http.post<any>(this.AppUrl + data, filterModel, httpOptions)
      .pipe(catchError(this.handleError));
  }

  BriefingReportYear(TokenKey: any): Observable<any> {
    this.setAPIUrl();
    let data = 'api/Report/BriefingReportYear';
    const httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Token': TokenKey }) };
    return this._http.get<any>(this.AppUrl + data, httpOptions)
      .pipe(catchError(this.handleError));
  }

  MonthlySummaryofDeliveredBriefingsByCompany(TokenKey: any, filterModel): Observable<any> {
    this.setAPIUrl();
    let data = 'api/Report/MonthlySummaryofDeliveredBriefingsByCompany';
    const httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Token': TokenKey }) };
    return this._http.post<any>(this.AppUrl + data, filterModel, httpOptions)
      .pipe(catchError(this.handleError));
  }

  MonthlyBillingOfRegulatoryMonitoringBulletins(TokenKey: any, filterModel): Observable<any> {
    this.setAPIUrl();
    let data = 'api/Report/MonthlyBillingOfRegulatoryMonitoringBulletins';
    const httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Token': TokenKey }) };
    return this._http.post<any>(this.AppUrl + data, filterModel, httpOptions)
      .pipe(catchError(this.handleError));
  }

  CompanySubscriptionReports(TokenKey: any, filterModel): Observable<any> {
    this.setAPIUrl();
    let data = 'api/Report/CompanySubscriptionReports';
    const httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Token': TokenKey }) };
    return this._http.post<any>(this.AppUrl + data, filterModel, httpOptions)
      .pipe(catchError(this.handleError));
  }

  IndividualCompanyBriefingSubscriptionReports(TokenKey: any, filterModel): Observable<any> {
    this.setAPIUrl();
    let data = 'api/Report/IndividualCompanyBriefingSubscriptionReports';
    const httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Token': TokenKey }) };
    return this._http.post<any>(this.AppUrl + data, filterModel, httpOptions)
      .pipe(catchError(this.handleError));
  }


  BriefingtitlesByJurisdictionReport(TokenKey: any, filterModel): Observable<any> {
    this.setAPIUrl();
    let data = 'api/Report/BriefingtitlesByJurisdictionReport';
    const httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Token': TokenKey }) };
    return this._http.post<any>(this.AppUrl + data, filterModel, httpOptions)
      .pipe(catchError(this.handleError));
  }

  BriefingtitlesByBriefingTypeReport(TokenKey: any, filterModel): Observable<any> {
    this.setAPIUrl();
    let data = 'api/Report/BriefingtitlesByBriefingTypeReport';
    const httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Token': TokenKey }) };
    return this._http.post<any>(this.AppUrl + data, filterModel, httpOptions)
      .pipe(catchError(this.handleError));
  }

  BriefingProcessingReport(TokenKey: any, filterModel): Observable<any> {
    this.setAPIUrl();
    let data = 'api/Report/BriefingProcessingReport';
    const httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Token': TokenKey }) };
    return this._http.post<any>(this.AppUrl + data, filterModel, httpOptions)
      .pipe(catchError(this.handleError));
  }

  VOIPCompanySubscriptionReports(TokenKey: any, filterModel): Observable<any> {
    this.setAPIUrl();    
    let data = 'api/Report/VOIPCompanySubscriptionReports';
    const httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Token': TokenKey }) };
    return this._http.post<any>(this.AppUrl + data, filterModel, httpOptions)
      .pipe(catchError(this.handleError));
  }

  GetYearFromBriefingMaster(TokenKey: any): Observable<any> {
    this.setAPIUrl();
    let data = 'api/BriefingReports/GetYearFromBriefingMaster';
    const httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Token': TokenKey }) };
    return this._http.get<any>(this.AppUrl + data, httpOptions)
      .pipe(catchError(this.handleError));
  }

  GetBriefingsDataByYear(TokenKey: string, year: number): Observable<any> {
    this.setAPIUrl();
    let data = 'api/BriefingReports/GetBriefingsDataByYear/' + year;
    const httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Token': TokenKey }) };
    return this._http.get<any>(this.AppUrl + data, httpOptions)
      .pipe(catchError(this.handleError));
  }

  GetBriefingStats(TokenKey: any, FromYear: number, ToYear: number): Observable<any> {
    this.setAPIUrl();
    let data = 'api/BriefingReports/GetBriefingStats/' + FromYear + '/' + ToYear;
    const httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Token': TokenKey }) };
    return this._http.get<any>(this.AppUrl + data, httpOptions)
      .pipe(catchError(this.handleError));
  }

  private handleError(error: Response | any) {
    console.error(error.message || error);
    return observableThrowError(error.status)
  }

  AllActiveSubscibersReports(TokenKey: any, filterModel): Observable<any> {
    this.setAPIUrl();    
    let data = 'api/Report/AllActiveSubscribersReport';
    const httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Token': TokenKey }) };
    return this._http.post<any>(this.AppUrl + data, filterModel, httpOptions)
      .pipe(catchError(this.handleError));
  }

  VoipTypeReport(TokenKey: any, filterModel): Observable<any> {
    this.setAPIUrl();    
    let data = 'api/Report/VoipTypeReport';
    const httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Token': TokenKey }) };
    return this._http.post<any>(this.AppUrl + data, filterModel, httpOptions)
      .pipe(catchError(this.handleError));
  }

  VoIPtitlesByJurisdictionReport(TokenKey: any, filterModel): Observable<any> {
    this.setAPIUrl();    
    let data = 'api/Report/VoIPtitlesByJurisdictionReport';
    const httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Token': TokenKey }) };
    return this._http.post<any>(this.AppUrl + data, filterModel, httpOptions)
      .pipe(catchError(this.handleError));
  }

  VoIPUpdatesCountsByUpdateCategoryReport(TokenKey: any, filterModel): Observable<any> {
    this.setAPIUrl();    
    let data = 'api/Report/VoIPUpdatesCountsByUpdateCategoryReport';
    const httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Token': TokenKey }) };
    return this._http.post<any>(this.AppUrl + data, filterModel, httpOptions)
      .pipe(catchError(this.handleError));
  }
}
