import { Component, HostListener, OnInit, ViewChild } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { ClientInformationModel } from 'src/Models/ClientInformationModel';
import { PricingQuotesService } from 'src/Services/pricing-quotes.service';
import { CheckAuth } from 'src/Services/utility.service';
import { HtmlToPdf } from 'src/Services/htmlToPdf';
import * as FileSaver from 'file-saver';

@Component({
  selector: 'app-pricing-quotes',
  templateUrl: './pricing-quotes.component.html',
  styleUrls: ['./pricing-quotes.component.css']
})
export class PricingQuotesComponent implements OnInit {

  stateCode: any;
  tokenKey: string;
  listofPricingQuotes: ClientInformationModel[];

  PageNumber: number = 1;
  PageSize: number;
  WhereCondition: string;
  OrderBy: string;
  CompanyName: string;
  isSubmit: boolean = false;


  upDownProposelDateCSS: string = '';
  upDownProposelModifiedDateCSS: string = '';
  upDownCompanyNameCSS: string = '';
  upDownQuoteNameCSS: string = '';
  upDownQuoteRequestedByCSS: string = '';
  upDownProposelByCSS: string = '';
  upDownProposelModifiedByCSS: string = '';
  upDownDisplayQuoteCSS: string;
  upDownQuoteSentCSS: string;
  upDownSentDateCSS: string;
  upDownQuoteStatusCSS: string;
  upDownMSAStatusCSS: string;
  upDownQuoteExpirationDateCSS: string;
  upDownQuotePreparedByCSS: string;

  isAscending: boolean = false;
  isRecord: boolean = false;
  isloading: boolean = false;
  SelectedTool: string;
  totalRecordCount: number = 0;
  clientInformationId: number = 0;
  Sent: string;
  DisplayQuote: string;
  isChecked: boolean = false;
  isCheckedDisplayQuote: boolean = false;
  blockedDocument: boolean;
  Sent1: string;
  duplicateClientInformationId: number = 0;

  dropdownListQuoteStatus = [];
  selectedItemsQuoteStatus = [];
  dropdownSettingsQuoteStatus = {};
  listOfQuoteStatusIds = [];

  dropdownListMSAStatus = [];
  selectedItemsMSAStatus = [];
  dropdownSettingsMSAStatus = {};
  listOfMSAStatusIds = [];

  dropdownListDisplayQuoteStatus = [];
  selectedItemsDisplayQuoteStatus = [];
  dropdownSettingsDisplayQuoteStatus = {};
  listOfDisplayQuoteStatusIds = [];
  displayQuoteStatusIds: string = '';

  dropdownListQuoteSentStatus = [];
  selectedItemsQuoteSentStatus = [];
  dropdownSettingsQuoteSentStatus = {};
  listOfQuoteSentStatusIds = [];
  quoteSentStatusIds: string = '';

  dropdownListQuoteStatusSearch = [];
  selectedItemsQuoteStatusSearch = [];
  dropdownSettingsQuoteStatusSearch = {};
  listOfQuoteStatusIdsSearch = [];
  quoteStatusIdsSearch: string = '';

  dropdownListMSAStatusSearch = [];
  selectedItemsMSAStatusSearch = [];
  dropdownSettingsMSAStatusSearch = {};
  listOfMSAStatusIdsSearch = [];
  msAStatusIdsSearch: string = '';

  quoteStatusId: number = 0;
  msaStatusId: number = 0;
  priceQuoteId: number = 0;
  AdminId: number = 0;

  // stateList: StateMasterModel[] = [];
  openMenuIndex: number = -1;

  constructor(private router: Router, private PricingQuotesMaster: PricingQuotesService, private checkAuth: CheckAuth
    , private titleService: Title, private toastr: ToastrService,private htmlToPdf: HtmlToPdf) {
    this.tokenExpireCalculation(sessionStorage.getItem('appStartTime'), sessionStorage.getItem('tokenExpiryValue'));
    this.listofPricingQuotes = [];
    this.PageNumber = 1;
    this.PageSize = 10;
    this.WhereCondition = 'null';
    this.OrderBy = 'CreatedDate desc';
    this.isAscending = true;
    this.upDownProposelDateCSS = 'fa fa-arrow-down'
    this.CompanyName = 'null';
    this.SelectedTool = sessionStorage.getItem('UserLoginTool');
    this.titleService.setTitle('Price Quote');
    sessionStorage.removeItem('ClientCompanyName');
    sessionStorage.removeItem('AdminClientCompanyId');

    this.dropdownSettingsQuoteStatus = {
      singleSelection: true,
      text: "Please Select Quote Status",
      enableSearchFilter: false,
      classes: "borderLessTextBoxGreen",
      badgeShowLimit: 3,
      noDataLabel: 'No Quote Status available',
      showCheckbox: false,
      enableFilterSelectAll: false
    };

    this.dropdownSettingsMSAStatus = {
      singleSelection: true,
      text: "Please Select MSA Status",
      enableSearchFilter: false,
      classes: "borderLessTextBoxGreen",
      badgeShowLimit: 3,
      noDataLabel: 'No MSA Status available',
      showCheckbox: false,
      enableFilterSelectAll: false
    };

    this.dropdownSettingsMSAStatusSearch = {
      singleSelection: false,
      text: "Please Select MSA Status",
      enableSearchFilter: false,
      classes: "borderLessTextBoxGreen",
      badgeShowLimit: 3,
      noDataLabel: 'No MSA Status available',
      showCheckbox: true,
      enableFilterSelectAll: true
    };

    this.dropdownSettingsQuoteStatusSearch = {
      singleSelection: false,
      text: "Please Select Quote Status",
      enableSearchFilter: false,
      classes: "borderLessTextBoxGreen",
      badgeShowLimit: 3,
      noDataLabel: 'No Quote Status available',
      showCheckbox: true,
      enableFilterSelectAll: true
    };

    this.dropdownSettingsQuoteSentStatus = {
      singleSelection: false,
      text: "Please Select Is Quote Sent",
      enableSearchFilter: false,
      classes: "borderLessTextBoxGreen",
      badgeShowLimit: 3,
      noDataLabel: 'No quote sent available',
      showCheckbox: true,
      enableFilterSelectAll: true
    };

    this.dropdownSettingsDisplayQuoteStatus = {
      singleSelection: false,
      text: "Please Select Display Quote",
      enableSearchFilter: false,
      classes: "borderLessTextBoxGreen",
      badgeShowLimit: 3,
      noDataLabel: 'No display quote available',
      showCheckbox: true,
      enableFilterSelectAll: true
    };
  }

  ngOnInit() {
    this.tokenKey = sessionStorage.getItem('tokenValue');
    this.isloading = true;
    this.BindDropdown();
    this.GetPricingQuotes();
  }

  BindDropdown() {
    this.dropdownListQuoteStatus = [];
    this.dropdownListQuoteStatus.push({ "id": 1, "itemName": "Pending" })
    this.dropdownListQuoteStatus.push({ "id": 2, "itemName": "Approved" })
    this.dropdownListQuoteStatus.push({ "id": 3, "itemName": "Rejected" })

    this.dropdownListMSAStatus = [];
    this.dropdownListMSAStatus.push({ "id": 1, "itemName": "Sent" })
    this.dropdownListMSAStatus.push({ "id": 2, "itemName": "Negotiating" })
    this.dropdownListMSAStatus.push({ "id": 3, "itemName": "Signed" })
    this.dropdownListMSAStatus.push({ "id": 4, "itemName": "Rejected" })

    this.dropdownListMSAStatusSearch = [];
    this.dropdownListMSAStatusSearch.push({ "id": 1, "itemName": "Sent" })
    this.dropdownListMSAStatusSearch.push({ "id": 2, "itemName": "Negotiating" })
    this.dropdownListMSAStatusSearch.push({ "id": 3, "itemName": "Signed" })
    this.dropdownListMSAStatusSearch.push({ "id": 4, "itemName": "Rejected" })

    this.dropdownListQuoteStatusSearch = [];
    this.dropdownListQuoteStatusSearch.push({ "id": 1, "itemName": "Pending" })
    this.dropdownListQuoteStatusSearch.push({ "id": 2, "itemName": "Approved" })
    this.dropdownListQuoteStatusSearch.push({ "id": 3, "itemName": "Rejected" })

    this.dropdownListQuoteSentStatus = [];
    this.dropdownListQuoteSentStatus.push({ "id": 1, "itemName": "Yes" })
    this.dropdownListQuoteSentStatus.push({ "id": 0, "itemName": "No" })

    this.dropdownListDisplayQuoteStatus = [];
    this.dropdownListDisplayQuoteStatus.push({ "id": 1, "itemName": "Yes" })
    this.dropdownListDisplayQuoteStatus.push({ "id": 0, "itemName": "No" })
  }

  tokenExpireCalculation(startDate: string, tokenExpiry: string) {
    this.checkAuth.tokenExpireCalculation(startDate, tokenExpiry);
  }

  pageChanged($event: any) {
    this.PageNumber = parseInt($event.page);
    this.GetPricingQuotes();
  }

  GetPricingQuotes() {
    if (this.CompanyName == "null") this.CompanyName = " ";

    this.displayQuoteStatusIds = ' ';
    if (this.listOfDisplayQuoteStatusIds.length > 0)
      this.displayQuoteStatusIds = this.listOfDisplayQuoteStatusIds.join(',')

    this.quoteSentStatusIds = ' ';
    if (this.listOfQuoteSentStatusIds.length > 0)
      this.quoteSentStatusIds = this.listOfQuoteSentStatusIds.join(',')
    
    this.msAStatusIdsSearch = ' ';
    if (this.listOfMSAStatusIdsSearch.length > 0)
      this.msAStatusIdsSearch = this.listOfMSAStatusIdsSearch.join(',')

    this.quoteStatusIdsSearch = ' ';
    if (this.listOfQuoteStatusIdsSearch.length > 0)
      this.quoteStatusIdsSearch = this.listOfQuoteStatusIdsSearch.join(',')
    
    this.PricingQuotesMaster.GetAllPricingQuotes(this.tokenKey, this.PageNumber, this.PageSize, this.CompanyName, this.OrderBy, this.displayQuoteStatusIds,this.quoteSentStatusIds,this.msAStatusIdsSearch,this.quoteStatusIdsSearch).subscribe(
      data => {
        this.isloading = false;
        this.listofPricingQuotes = data['pricingQuotes'];
        if (this.listofPricingQuotes != null) {
          if (this.listofPricingQuotes.length == 0)
            this.isRecord = true;
          else {
            this.isRecord = false;
            this.totalRecordCount = this.listofPricingQuotes[0].totalRecordCount;
          }
        }
        else
          this.isRecord = true;
      },
      error => { this.stateCode = error }
    );

  }

  keywordFilters($event) {
    this.PageNumber = 1;
    if ($event.target.value != "" && $event.target.value != null)
      this.CompanyName = $event.target.value;
    else
      this.CompanyName = 'null';

    this.GetPricingQuotes();
  }

  sort(sortBy: string) {
    if (!this.isAscending) {
      this.isAscending = true;
      this.OrderBy = sortBy + ' DESC';

      if (sortBy === "CompanyName") {
        this.upDownCompanyNameCSS = 'fa fa-arrow-down';
        this.upDownQuoteNameCSS = '';
        this.upDownQuoteRequestedByCSS = '';
        this.upDownProposelDateCSS = '';
        this.upDownProposelByCSS = '';
        this.upDownProposelModifiedDateCSS = '';
        this.upDownProposelModifiedByCSS = '';
        this.upDownDisplayQuoteCSS = ''
        this.upDownQuoteSentCSS = '';
        this.upDownSentDateCSS = '';
        this.upDownQuoteStatusCSS = '';
        this.upDownMSAStatusCSS = '';
        this.upDownQuoteExpirationDateCSS = '';
        this.upDownQuotePreparedByCSS = '';
      }
      else if (sortBy === "QuoteName") {
        this.upDownCompanyNameCSS = '';
        this.upDownQuoteNameCSS = 'fa fa-arrow-down';
        this.upDownQuoteRequestedByCSS = '';
        this.upDownProposelDateCSS = '';
        this.upDownProposelByCSS = '';
        this.upDownProposelModifiedDateCSS = '';
        this.upDownProposelModifiedByCSS = '';
        this.upDownDisplayQuoteCSS = ''
        this.upDownQuoteSentCSS = '';
        this.upDownSentDateCSS = '';
        this.upDownQuoteStatusCSS = '';
        this.upDownMSAStatusCSS = '';
        this.upDownQuoteExpirationDateCSS = '';
        this.upDownQuotePreparedByCSS = '';
      }
      else if (sortBy === "QuoteRequestedBy") {
        this.upDownCompanyNameCSS = '';
        this.upDownQuoteNameCSS = '';
        this.upDownQuoteRequestedByCSS = 'fa fa-arrow-down';
        this.upDownProposelDateCSS = '';
        this.upDownProposelByCSS = '';
        this.upDownProposelModifiedDateCSS = '';
        this.upDownProposelModifiedByCSS = '';
        this.upDownDisplayQuoteCSS = ''
        this.upDownQuoteSentCSS = '';
        this.upDownSentDateCSS = '';
        this.upDownQuoteStatusCSS = '';
        this.upDownMSAStatusCSS = '';
        this.upDownQuoteExpirationDateCSS = '';
        this.upDownQuotePreparedByCSS = '';
      }
      else if (sortBy === "CreatedDate") {
        this.upDownCompanyNameCSS = '';
        this.upDownQuoteNameCSS = '';
        this.upDownQuoteRequestedByCSS = '';
        this.upDownProposelDateCSS = 'fa fa-arrow-down';
        this.upDownProposelByCSS = '';
        this.upDownProposelModifiedDateCSS = '';
        this.upDownProposelModifiedByCSS = '';
        this.upDownDisplayQuoteCSS = ''
        this.upDownQuoteSentCSS = '';
        this.upDownSentDateCSS = '';
        this.upDownQuoteStatusCSS = '';
        this.upDownMSAStatusCSS = '';
        this.upDownQuoteExpirationDateCSS = '';
        this.upDownQuotePreparedByCSS = '';
      }
      else if (sortBy === "CreatedByName") {
        this.upDownCompanyNameCSS = '';
        this.upDownQuoteNameCSS = '';
        this.upDownQuoteRequestedByCSS = '';
        this.upDownProposelDateCSS = '';
        this.upDownProposelByCSS = 'fa fa-arrow-down';
        this.upDownProposelModifiedDateCSS = '';
        this.upDownProposelModifiedByCSS = '';
        this.upDownDisplayQuoteCSS = ''
        this.upDownQuoteSentCSS = '';
        this.upDownSentDateCSS = '';
        this.upDownQuoteStatusCSS = '';
        this.upDownMSAStatusCSS = '';
        this.upDownQuoteExpirationDateCSS = '';
        this.upDownQuotePreparedByCSS = '';
      }
      else if (sortBy === "UpdatedDate") {
        this.upDownCompanyNameCSS = '';
        this.upDownQuoteNameCSS = '';
        this.upDownQuoteRequestedByCSS = '';
        this.upDownProposelDateCSS = '';
        this.upDownProposelByCSS = '';
        this.upDownProposelModifiedDateCSS = 'fa fa-arrow-down';
        this.upDownProposelModifiedByCSS = '';
        this.upDownDisplayQuoteCSS = ''
        this.upDownQuoteSentCSS = '';
        this.upDownSentDateCSS = '';
        this.upDownQuoteStatusCSS = '';
        this.upDownMSAStatusCSS = '';
        this.upDownQuoteExpirationDateCSS = '';
        this.upDownQuotePreparedByCSS = '';
      }
      else if (sortBy === "UpdatedByName") {
        this.upDownCompanyNameCSS = '';
        this.upDownQuoteNameCSS = '';
        this.upDownQuoteRequestedByCSS = '';
        this.upDownProposelDateCSS = '';
        this.upDownProposelByCSS = '';
        this.upDownProposelModifiedDateCSS = '';
        this.upDownProposelModifiedByCSS = 'fa fa-arrow-down';
        this.upDownDisplayQuoteCSS = ''
        this.upDownQuoteSentCSS = '';
        this.upDownSentDateCSS = '';
        this.upDownQuoteStatusCSS = '';
        this.upDownMSAStatusCSS = '';
        this.upDownQuoteExpirationDateCSS = '';
        this.upDownQuotePreparedByCSS = '';
      }
      else if (sortBy === "isDisplayQuote") {
        this.upDownCompanyNameCSS = '';
        this.upDownQuoteNameCSS = '';
        this.upDownQuoteRequestedByCSS = '';
        this.upDownProposelDateCSS = '';
        this.upDownProposelByCSS = '';
        this.upDownProposelModifiedDateCSS = '';
        this.upDownProposelModifiedByCSS = '';
        this.upDownDisplayQuoteCSS = 'fa fa-arrow-down'
        this.upDownQuoteSentCSS = '';
        this.upDownSentDateCSS = '';
        this.upDownQuoteStatusCSS = '';
        this.upDownMSAStatusCSS = '';
        this.upDownQuoteExpirationDateCSS = '';
        this.upDownQuotePreparedByCSS = '';
      }
      else if (sortBy === "isQuoteSent") {
        this.upDownCompanyNameCSS = '';
        this.upDownQuoteNameCSS = '';
        this.upDownQuoteRequestedByCSS = '';
        this.upDownProposelDateCSS = '';
        this.upDownProposelByCSS = '';
        this.upDownProposelModifiedDateCSS = '';
        this.upDownProposelModifiedByCSS = '';
        this.upDownDisplayQuoteCSS = ''
        this.upDownQuoteSentCSS = 'fa fa-arrow-down';
        this.upDownSentDateCSS = '';
        this.upDownQuoteStatusCSS = '';
        this.upDownMSAStatusCSS = '';
        this.upDownQuoteExpirationDateCSS = '';
        this.upDownQuotePreparedByCSS = '';
      }
      else if (sortBy === "QuoteSentDate") {
        this.upDownCompanyNameCSS = '';
        this.upDownQuoteNameCSS = '';
        this.upDownQuoteRequestedByCSS = '';
        this.upDownProposelDateCSS = '';
        this.upDownProposelByCSS = '';
        this.upDownProposelModifiedDateCSS = '';
        this.upDownProposelModifiedByCSS = '';
        this.upDownDisplayQuoteCSS = ''
        this.upDownQuoteSentCSS = '';
        this.upDownSentDateCSS = 'fa fa-arrow-down';
        this.upDownQuoteStatusCSS = '';
        this.upDownMSAStatusCSS = '';
        this.upDownQuoteExpirationDateCSS = '';
        this.upDownQuotePreparedByCSS = '';
      }
      else if (sortBy === "QuoteStatus") {
        this.upDownCompanyNameCSS = '';
        this.upDownQuoteNameCSS = '';
        this.upDownQuoteRequestedByCSS = '';
        this.upDownProposelDateCSS = '';
        this.upDownProposelByCSS = '';
        this.upDownProposelModifiedDateCSS = '';
        this.upDownProposelModifiedByCSS = '';
        this.upDownDisplayQuoteCSS = ''
        this.upDownQuoteSentCSS = '';
        this.upDownSentDateCSS = '';
        this.upDownQuoteStatusCSS = 'fa fa-arrow-down';
        this.upDownMSAStatusCSS = '';
        this.upDownQuoteExpirationDateCSS = '';
        this.upDownQuotePreparedByCSS = '';
      }
      else if (sortBy === "msaStatus") {
        this.upDownCompanyNameCSS = '';
        this.upDownQuoteNameCSS = '';
        this.upDownQuoteRequestedByCSS = '';
        this.upDownProposelDateCSS = '';
        this.upDownProposelByCSS = '';
        this.upDownProposelModifiedDateCSS = '';
        this.upDownProposelModifiedByCSS = '';
        this.upDownDisplayQuoteCSS = ''
        this.upDownQuoteSentCSS = '';
        this.upDownSentDateCSS = '';
        this.upDownQuoteStatusCSS = '';
        this.upDownMSAStatusCSS = 'fa fa-arrow-down';
        this.upDownQuoteExpirationDateCSS = '';
        this.upDownQuotePreparedByCSS = '';
      }
      else if (sortBy === "quoteExpiryDate") {
        this.upDownCompanyNameCSS = '';
        this.upDownQuoteNameCSS = '';
        this.upDownQuoteRequestedByCSS = '';
        this.upDownProposelDateCSS = '';
        this.upDownProposelByCSS = '';
        this.upDownProposelModifiedDateCSS = '';
        this.upDownProposelModifiedByCSS = '';
        this.upDownDisplayQuoteCSS = ''
        this.upDownQuoteSentCSS = '';
        this.upDownSentDateCSS = '';
        this.upDownQuoteStatusCSS = '';
        this.upDownMSAStatusCSS = '';
        this.upDownQuoteExpirationDateCSS = 'fa fa-arrow-down';
        this.upDownQuotePreparedByCSS = '';
      }
      else if (sortBy === "quotePreparedBy") {
        this.upDownCompanyNameCSS = '';
        this.upDownQuoteNameCSS = '';
        this.upDownQuoteRequestedByCSS = '';
        this.upDownProposelDateCSS = '';
        this.upDownProposelByCSS = '';
        this.upDownProposelModifiedDateCSS = '';
        this.upDownProposelModifiedByCSS = '';
        this.upDownDisplayQuoteCSS = ''
        this.upDownQuoteSentCSS = '';
        this.upDownSentDateCSS = '';
        this.upDownQuoteStatusCSS = '';
        this.upDownMSAStatusCSS = '';
        this.upDownQuoteExpirationDateCSS = '';
        this.upDownQuotePreparedByCSS = 'fa fa-arrow-down';
      }
    }
    else {
      this.isAscending = false;
      this.OrderBy = sortBy;

      if (sortBy === "CompanyName") {
        this.upDownCompanyNameCSS = 'fa fa-arrow-up';
        this.upDownQuoteNameCSS = '';
        this.upDownQuoteRequestedByCSS = '';
        this.upDownProposelDateCSS = '';
        this.upDownProposelByCSS = '';
        this.upDownProposelModifiedDateCSS = '';
        this.upDownProposelModifiedByCSS = '';
        this.upDownDisplayQuoteCSS = ''
        this.upDownQuoteSentCSS = '';
        this.upDownSentDateCSS = '';
        this.upDownQuoteStatusCSS = '';
        this.upDownMSAStatusCSS = '';
        this.upDownQuoteExpirationDateCSS = '';
        this.upDownQuotePreparedByCSS = '';
      }
      else if (sortBy === "QuoteName") {
        this.upDownCompanyNameCSS = '';
        this.upDownQuoteNameCSS = 'fa fa-arrow-up';
        this.upDownQuoteRequestedByCSS = '';
        this.upDownProposelDateCSS = '';
        this.upDownProposelByCSS = '';
        this.upDownProposelModifiedDateCSS = '';
        this.upDownProposelModifiedByCSS = '';
        this.upDownDisplayQuoteCSS = ''
        this.upDownQuoteSentCSS = '';
        this.upDownSentDateCSS = '';
        this.upDownQuoteStatusCSS = '';
        this.upDownMSAStatusCSS = '';
        this.upDownQuoteExpirationDateCSS = '';
        this.upDownQuotePreparedByCSS = '';
      }
      else if (sortBy === "QuoteRequestedBy") {
        this.upDownCompanyNameCSS = '';
        this.upDownQuoteNameCSS = '';
        this.upDownQuoteRequestedByCSS = 'fa fa-arrow-up';
        this.upDownProposelDateCSS = '';
        this.upDownProposelByCSS = '';
        this.upDownProposelModifiedDateCSS = '';
        this.upDownProposelModifiedByCSS = '';
        this.upDownDisplayQuoteCSS = ''
        this.upDownQuoteSentCSS = '';
        this.upDownSentDateCSS = '';
        this.upDownQuoteStatusCSS = '';
        this.upDownMSAStatusCSS = '';
        this.upDownQuoteExpirationDateCSS = '';
        this.upDownQuotePreparedByCSS = '';
      }
      else if (sortBy === "CreatedDate") {
        this.upDownCompanyNameCSS = '';
        this.upDownQuoteNameCSS = '';
        this.upDownQuoteRequestedByCSS = '';
        this.upDownProposelDateCSS = 'fa fa-arrow-up';
        this.upDownProposelByCSS = '';
        this.upDownProposelModifiedDateCSS = '';
        this.upDownProposelModifiedByCSS = '';
        this.upDownDisplayQuoteCSS = ''
        this.upDownQuoteSentCSS = '';
        this.upDownSentDateCSS = '';
        this.upDownQuoteStatusCSS = '';
        this.upDownMSAStatusCSS = '';
        this.upDownQuoteExpirationDateCSS = '';
        this.upDownQuotePreparedByCSS = '';
      }
      else if (sortBy === "CreatedByName") {
        this.upDownCompanyNameCSS = '';
        this.upDownQuoteNameCSS = '';
        this.upDownQuoteRequestedByCSS = '';
        this.upDownProposelDateCSS = '';
        this.upDownProposelByCSS = 'fa fa-arrow-up';
        this.upDownProposelModifiedDateCSS = ''
        this.upDownProposelModifiedByCSS = '';
        this.upDownDisplayQuoteCSS = ''
        this.upDownQuoteSentCSS = '';
        this.upDownSentDateCSS = '';
        this.upDownQuoteStatusCSS = '';
        this.upDownMSAStatusCSS = '';
        this.upDownQuoteExpirationDateCSS = '';
        this.upDownQuotePreparedByCSS = '';
      }
      else if (sortBy === "UpdatedDate") {
        this.upDownCompanyNameCSS = '';
        this.upDownQuoteNameCSS = '';
        this.upDownQuoteRequestedByCSS = '';
        this.upDownProposelDateCSS = '';
        this.upDownProposelByCSS = '';
        this.upDownProposelModifiedDateCSS = 'fa fa-arrow-up';
        this.upDownProposelModifiedByCSS = '';
        this.upDownDisplayQuoteCSS = ''
        this.upDownQuoteSentCSS = '';
        this.upDownSentDateCSS = '';
        this.upDownQuoteStatusCSS = '';
        this.upDownMSAStatusCSS = '';
        this.upDownQuoteExpirationDateCSS = '';
        this.upDownQuotePreparedByCSS = '';
      }
      else if (sortBy === "UpdatedByName") {
        this.upDownCompanyNameCSS = '';
        this.upDownQuoteNameCSS = '';
        this.upDownQuoteRequestedByCSS = '';
        this.upDownProposelDateCSS = '';
        this.upDownProposelByCSS = '';
        this.upDownProposelModifiedDateCSS = '';
        this.upDownProposelModifiedByCSS = 'fa fa-arrow-up';
        this.upDownDisplayQuoteCSS = ''
        this.upDownQuoteSentCSS = '';
        this.upDownSentDateCSS = '';
        this.upDownQuoteStatusCSS = '';
        this.upDownMSAStatusCSS = '';
        this.upDownQuoteExpirationDateCSS = '';
        this.upDownQuotePreparedByCSS = '';
      }
      else if (sortBy === "isDisplayQuote") {
        this.upDownCompanyNameCSS = '';
        this.upDownQuoteNameCSS = '';
        this.upDownQuoteRequestedByCSS = '';
        this.upDownProposelDateCSS = '';
        this.upDownProposelByCSS = '';
        this.upDownProposelModifiedDateCSS = '';
        this.upDownProposelModifiedByCSS = '';
        this.upDownDisplayQuoteCSS = 'fa fa-arrow-up'
        this.upDownQuoteSentCSS = '';
        this.upDownSentDateCSS = '';
        this.upDownQuoteStatusCSS = '';
        this.upDownMSAStatusCSS = '';
        this.upDownQuoteExpirationDateCSS = '';
        this.upDownQuotePreparedByCSS = '';
      }
      else if (sortBy === "isQuoteSent") {
        this.upDownCompanyNameCSS = '';
        this.upDownQuoteNameCSS = '';
        this.upDownQuoteRequestedByCSS = '';
        this.upDownProposelDateCSS = '';
        this.upDownProposelByCSS = '';
        this.upDownProposelModifiedDateCSS = '';
        this.upDownProposelModifiedByCSS = '';
        this.upDownDisplayQuoteCSS = ''
        this.upDownQuoteSentCSS = 'fa fa-arrow-up';
        this.upDownSentDateCSS = '';
        this.upDownQuoteStatusCSS = '';
        this.upDownMSAStatusCSS = '';
        this.upDownQuoteExpirationDateCSS = '';
        this.upDownQuotePreparedByCSS = '';
      }
      else if (sortBy === "QuoteSentDate") {
        this.upDownCompanyNameCSS = '';
        this.upDownQuoteNameCSS = '';
        this.upDownQuoteRequestedByCSS = '';
        this.upDownProposelDateCSS = '';
        this.upDownProposelByCSS = '';
        this.upDownProposelModifiedDateCSS = '';
        this.upDownProposelModifiedByCSS = '';
        this.upDownDisplayQuoteCSS = ''
        this.upDownQuoteSentCSS = '';
        this.upDownSentDateCSS = 'fa fa-arrow-up';
        this.upDownQuoteStatusCSS = '';
        this.upDownMSAStatusCSS = '';
        this.upDownQuoteExpirationDateCSS = '';
        this.upDownQuotePreparedByCSS = '';
      }
      else if (sortBy === "QuoteStatus") {
        this.upDownCompanyNameCSS = '';
        this.upDownQuoteNameCSS = '';
        this.upDownQuoteRequestedByCSS = '';
        this.upDownProposelDateCSS = '';
        this.upDownProposelByCSS = '';
        this.upDownProposelModifiedDateCSS = '';
        this.upDownProposelModifiedByCSS = '';
        this.upDownDisplayQuoteCSS = ''
        this.upDownQuoteSentCSS = '';
        this.upDownSentDateCSS = '';
        this.upDownQuoteStatusCSS = 'fa fa-arrow-up';
        this.upDownMSAStatusCSS = '';
        this.upDownQuoteExpirationDateCSS = '';
        this.upDownQuotePreparedByCSS = '';
      }
      else if (sortBy === "msaStatus") {
        this.upDownCompanyNameCSS = '';
        this.upDownQuoteNameCSS = '';
        this.upDownQuoteRequestedByCSS = '';
        this.upDownProposelDateCSS = '';
        this.upDownProposelByCSS = '';
        this.upDownProposelModifiedDateCSS = '';
        this.upDownProposelModifiedByCSS = '';
        this.upDownDisplayQuoteCSS = ''
        this.upDownQuoteSentCSS = '';
        this.upDownSentDateCSS = '';
        this.upDownQuoteStatusCSS = '';
        this.upDownMSAStatusCSS = 'fa fa-arrow-up';
        this.upDownQuoteExpirationDateCSS = '';
        this.upDownQuotePreparedByCSS = '';
      }
      else if (sortBy === "quoteExpiryDate") {
        this.upDownCompanyNameCSS = '';
        this.upDownQuoteNameCSS = '';
        this.upDownQuoteRequestedByCSS = '';
        this.upDownProposelDateCSS = '';
        this.upDownProposelByCSS = '';
        this.upDownProposelModifiedDateCSS = '';
        this.upDownProposelModifiedByCSS = '';
        this.upDownDisplayQuoteCSS = ''
        this.upDownQuoteSentCSS = '';
        this.upDownSentDateCSS = '';
        this.upDownQuoteStatusCSS = '';
        this.upDownMSAStatusCSS = '';
        this.upDownQuoteExpirationDateCSS = 'fa fa-arrow-up';
        this.upDownQuotePreparedByCSS = '';
      }
      else if (sortBy === "quotePreparedBy") {
        this.upDownCompanyNameCSS = '';
        this.upDownQuoteNameCSS = '';
        this.upDownQuoteRequestedByCSS = '';
        this.upDownProposelDateCSS = '';
        this.upDownProposelByCSS = '';
        this.upDownProposelModifiedDateCSS = '';
        this.upDownProposelModifiedByCSS = '';
        this.upDownDisplayQuoteCSS = ''
        this.upDownQuoteSentCSS = '';
        this.upDownSentDateCSS = '';
        this.upDownQuoteStatusCSS = '';
        this.upDownMSAStatusCSS = '';
        this.upDownQuoteExpirationDateCSS = '';
        this.upDownQuotePreparedByCSS = 'fa fa-arrow-up';
      }
    }
    this.GetPricingQuotes();
  }

  addPricingQuotes() {
    let navigation = '/admin/' + this.SelectedTool + '/Company-Selection';
    this.router.navigate([navigation]);
  }

  updatePricingQuotes(item,companyName) {
      sessionStorage.setItem('AdminClientCompanyId',item.clientCompanyId)
     sessionStorage.setItem('AdminPriceQuoteId',item.priceQuoteId);
     sessionStorage.setItem('ClientCompanyName',companyName);
    let navigation = '/admin/' + this.SelectedTool + '/Company-Selection/'+item.priceQuoteId+'/'+item.clientCompanyId;
    this.router.navigate([navigation]);
  }

  pricingQuotes(priceQuoteId, companyName) {
    sessionStorage.setItem('ClientCompanyName', companyName);
    let navigation = '/admin/' + this.SelectedTool + '/manage-baserate/' + priceQuoteId;
    this.router.navigate([navigation]);
  }

  setDeleteData(id) {
    this.priceQuoteId = id;
  }


  deletePricingQuotes(priceQuoteId) {
    this.isloading = true;
    this.PricingQuotesMaster.DeletePricingQuotes(this.tokenKey, priceQuoteId).subscribe(
      data => {
        this.isloading = false;
        if (data['status'] == 'Success') {
          if (data['message'] != "")
            this.showMessage(data['message'], 'Warning', 'warning')
          else
            this.showMessage("Price Quote Deleted successfully", 'Success', 'success')
        }
        this.GetPricingQuotes();
      },
      error => { this.stateCode = error }
    );
  }

  showMessage(msg: string, msgTitle: string, msgType: string) {
    if (msgType == 'success') {
      setTimeout(() => this.toastr.success(msg, msgTitle));
    }
    else if (msgType == 'error') {
      setTimeout(() => this.toastr.error(msg, msgTitle));
    }
    else if (msgType == 'warning') {
      setTimeout(() => this.toastr.warning(msg, msgTitle));
    }
  }

  onCheckboxChange(event, item) {
    this.isChecked = event.target.checked;
    this.priceQuoteId = item.priceQuoteId;

    if (event.target.checked)
      this.Sent = "Sent";
    else
      this.Sent = "Unsent";

    document.getElementById("btnopenEditpopup").click();
    return;
  }
  onDisplayQuoteCheckboxChange(event, item) {
    this.isCheckedDisplayQuote = event.target.checked;
    this.priceQuoteId = item.priceQuoteId;

    if (event.target.checked)
      this.DisplayQuote = "Display";
    else
      this.DisplayQuote = "Hide";

    document.getElementById("btnopenEditpopup1").click();
    return;
  }

  priceQuoteSent() {
    var PriceQuoteData = this.listofPricingQuotes.filter(x => x.priceQuoteId == this.priceQuoteId)[0];
    if (PriceQuoteData != null) {
      PriceQuoteData.isQuoteSent = this.isChecked;
    }
    this.isloading = true;
    this.PricingQuotesMaster.PriceQuoteSent(this.tokenKey, this.priceQuoteId, this.isChecked).subscribe(
      data => {
        this.isloading = false;
        if (data['status'] == 'Success') {
          if (data['message'] != "")
            this.showMessage(data['message'], 'Warning', 'warning')
          else {
            if (this.Sent == 'Sent')
              this.showMessage("Marked as Sent this Quote successfully", 'Success', 'success')
            else
              this.showMessage("Marked as Unsent this Quote successfully", 'Success', 'success')
          }
        }
        this.GetPricingQuotes();
      },
      error => { this.stateCode = error }
    );
  }

  priceQuoteSentNo() {
    var PriceQuoteData = this.listofPricingQuotes.filter(x => x.priceQuoteId == this.priceQuoteId)[0];
    if (PriceQuoteData != null) {
      PriceQuoteData.isQuoteSent = !this.isChecked;
    }
  }

  priceQuoteDisplayNo() {
    var PriceQuoteData = this.listofPricingQuotes.filter(x => x.priceQuoteId == this.priceQuoteId)[0];
    if (PriceQuoteData != null) {
      PriceQuoteData.isDisplayQuote = !this.isCheckedDisplayQuote;
    }
  }

  priceQuoteDisplay() {
    var PriceQuoteData = this.listofPricingQuotes.filter(x => x.priceQuoteId == this.priceQuoteId)[0];
    if (PriceQuoteData != null) {
      PriceQuoteData.isDisplayQuote = this.isCheckedDisplayQuote;
    }
    this.isloading = true;
    this.PricingQuotesMaster.PriceQuoteDisplay(this.tokenKey, this.priceQuoteId, this.isCheckedDisplayQuote).subscribe(
      data => {
        this.isloading = false;
        if (data['status'] == 'Success') {
          if (data['message'] != "")
            this.showMessage(data['message'], 'Warning', 'warning')
          else {
            if (this.DisplayQuote == 'Display')
              this.showMessage("Marked as Display this Quote PDF successfully", 'Success', 'success')
            else
              this.showMessage("Marked as Hidden this Quote PDF successfully", 'Success', 'success')
          }
        }
        this.GetPricingQuotes();
      },
      error => { this.stateCode = error }
    );
  }

  closepopup() {
    var PriceQuoteData = this.listofPricingQuotes.filter(x => x.priceQuoteId == this.priceQuoteId)[0];
    if (PriceQuoteData != null) {
      PriceQuoteData.isQuoteSent = !this.isChecked;
    }
  }

  closepopupDisplayQuote() {
    var PriceQuoteData = this.listofPricingQuotes.filter(x => x.priceQuoteId == this.priceQuoteId)[0];
    if (PriceQuoteData != null) {
      PriceQuoteData.isDisplayQuote = !this.isCheckedDisplayQuote;
    }
  }


  updateOnboardingData(clientInformationId, companyName, priceQuoteId) {
    sessionStorage.setItem('ClientCompanyName', companyName);
    let navigation = '/admin/' + this.SelectedTool + '/add-company-info/' + clientInformationId + '/' + priceQuoteId;
    this.router.navigate([navigation]);
  }

  onCheckboxChange1(item) {
    this.isSubmit = false;
    this.priceQuoteId = item.priceQuoteId;
    var PriceQuoteData1 = this.dropdownListQuoteStatus.filter(x => x.id == item.isQuoteApproved)[0];
    if (PriceQuoteData1 != null) {
      this.selectedItemsQuoteStatus = [];
      this.quoteStatusId = PriceQuoteData1.id;
      this.selectedItemsQuoteStatus.push({ "id": PriceQuoteData1.id, "itemName": PriceQuoteData1.itemName })
    }

    document.getElementById("btnopenApprovedpopup").click();
    return;
  }

  onSelectQuoteStatus($event) {
    this.quoteStatusId = $event.id;
  }

  onDeSelectQuoteStatus($event) {
    this.quoteStatusId = 0;
  }

  onDeSelectAllQuoteStatus($event) {
    this.quoteStatusId = 0;
  }

  priceQuoteApproved() {
    this.isSubmit = true;
    if (this.quoteStatusId == 0)
      return;
    var PriceQuoteData1 = this.listofPricingQuotes.filter(x => x.priceQuoteId == this.priceQuoteId)[0];
    if (PriceQuoteData1 != null) {
      PriceQuoteData1.isQuoteApproved = this.quoteStatusId;
    }

    document.getElementById("closebutton").click();

    this.isloading = true;
    this.PricingQuotesMaster.PriceQuoteApproved(this.tokenKey, this.priceQuoteId, this.quoteStatusId, "").subscribe(
      data => {
        this.isloading = false;
        if (data['status'] == 'Success') {
          this.isSubmit = false;
          if (data['message'] != "")
            this.showMessage(data['message'], 'Warning', 'warning')
          else {
            this.showMessage("Quote Status update successfully", 'Success', 'success')
          }
        }
        this.GetPricingQuotes();
      },
      error => { this.stateCode = error }
    );
  }

  priceQuoteApprovedNo() {
    var PriceQuoteData1 = this.listofPricingQuotes.filter(x => x.clientInformationId == this.clientInformationId)[0];
  }

  closepopupApproved() {
    var PriceQuoteData1 = this.listofPricingQuotes.filter(x => x.clientInformationId == this.clientInformationId)[0];
  }

  onChangeMSAStatus(item) {
    this.isSubmit = false;
    this.priceQuoteId = item.priceQuoteId;
    var PriceQuoteData1 = this.dropdownListMSAStatus.filter(x => x.id == item.msaStatus)[0];
    this.msaStatusId = null;
    this.selectedItemsMSAStatus = [];

    if (PriceQuoteData1 != null && PriceQuoteData1 != undefined) {
      this.msaStatusId = PriceQuoteData1.id;
      this.selectedItemsMSAStatus.push({ "id": PriceQuoteData1.id, "itemName": PriceQuoteData1.itemName })
    }
    document.getElementById("btnopenMSApopup").click();
    return;
  }

  onSelectMSAStatus($event) {
    this.msaStatusId = $event.id;
  }

  onDeSelectMSAStatus($event) {
    this.msaStatusId = 0;
  }

  onDeSelectAllMSAStatus($event) {
    this.msaStatusId = 0;
  }

  MSAApproved() {
    this.isSubmit = true;
    if (this.msaStatusId == 0)
      return;
    var PriceQuoteData1 = this.listofPricingQuotes.filter(x => x.priceQuoteId == this.priceQuoteId)[0];
    if (PriceQuoteData1 != null) {
      PriceQuoteData1.msaStatus = this.msaStatusId;
    }

    document.getElementById("closebutton1").click();

    this.isloading = true;
    this.PricingQuotesMaster.MSAStatusApproved(this.tokenKey, this.priceQuoteId, this.msaStatusId).subscribe(
      data => {
        this.isloading = false;
        if (data['status'] == 'Success') {
          this.isSubmit = false;
          if (data['message'] != "")
            this.showMessage(data['message'], 'Warning', 'warning')
          else {
            this.showMessage("MSA Status update successfully", 'Success', 'success')
          }
        }
        this.GetPricingQuotes();
      },
      error => { this.stateCode = error }
    );
  }

  MSAApprovedNo() {
    var PriceQuoteData1 = this.listofPricingQuotes.filter(x => x.clientInformationId == this.clientInformationId)[0];
  }

  setDuplicateId(duplicateClientInformationId) {
    this.duplicateClientInformationId = duplicateClientInformationId;
  }

  duplicateQuoteData(duplicateClientInformationId) {

    this.isloading = true;
    this.AdminId = +sessionStorage.getItem('AdminUserId')
    this.PricingQuotesMaster.CreateDuplicateRecord(this.tokenKey, duplicateClientInformationId, this.AdminId).subscribe(
      result => {
        this.isloading = false;
        if (result['status'] == 'Success') {
          if (result['clientInformationId'] != 0) {
            let navigation = '/admin/' + this.SelectedTool + '/company-info/' + result['clientInformationId'];
            this.router.navigate([navigation]);
          }
        }
      },
      error => {
        this.showMessage(error, 'Error', 'error');
      }
    );
  }

  downloadOnboardingdetails(clientInformationId, priceQuoteId) {
    this.isloading = true;

    this.PricingQuotesMaster.GenerateOnboardingDetails(clientInformationId, priceQuoteId, this.tokenKey).subscribe(
      data => {
        if (data['status'] == 'Success') {
          this.downloadFile('OnboardingDetails.pdf', data['url'], data['fileName']);
          this.isloading = false;
        }
        else if (data['status'] == 'datanotavailable') {
          this.showMessage(data['message'], 'Data not available. Please fill in the data first.', 'warning')
        }
      },
      error => {
        this.isloading = false;
      }
    );
  }

  public downloadFile(filename: any, url: any, orignalFileName: any) {
    this.htmlToPdf.downloadPdf(url).subscribe(
      (res) => {
        FileSaver.saveAs(res, filename);
        sessionStorage.setItem('fileloading', 'false');
        this.htmlToPdf.DeletePDF(this.tokenKey, orignalFileName).subscribe(
          data => {
            this.isloading = false;
          },
        );
      }
    );
  }

  onSelectDislpayQuoteStatus($event) {
    this.listOfDisplayQuoteStatusIds = this.removeElemetninArray(this.listOfDisplayQuoteStatusIds, $event.id)
    this.listOfDisplayQuoteStatusIds.push($event.id);
    this.GetPricingQuotes();
  }

  onSelectDisplayQuoteStatusAll($event) {
    for (let iCounter = 0; iCounter < $event.length; iCounter++) {
      if (this.listOfDisplayQuoteStatusIds != null) {
        if (this.listOfDisplayQuoteStatusIds.length > 0) {
          this.listOfDisplayQuoteStatusIds = this.removeElemetninArray(this.listOfDisplayQuoteStatusIds, $event[iCounter].id)
          this.listOfDisplayQuoteStatusIds.push($event[iCounter].id);
        }
        else {
          this.listOfDisplayQuoteStatusIds.push($event[iCounter].id);
        }
      }
    }
    this.GetPricingQuotes();
  }

  onDeSelectDisplayQuoteStatus($event) {
    if (this.listOfDisplayQuoteStatusIds != null) {
      if (this.listOfDisplayQuoteStatusIds.length > 0) {
        this.listOfDisplayQuoteStatusIds = this.removeElemetninArray(this.listOfDisplayQuoteStatusIds, $event.id)
      }
    }
    this.GetPricingQuotes();
  }

  onDeSelectDisplayQuoteStatusAll($event) {
    this.listOfDisplayQuoteStatusIds=[];
    this.GetPricingQuotes();
  }

  onSelectQuoteSentStatus($event) {
    this.listOfQuoteSentStatusIds = this.removeElemetninArray(this.listOfQuoteSentStatusIds, $event.id)
    this.listOfQuoteSentStatusIds.push($event.id);
    this.GetPricingQuotes();
  }

  onSelectQuoteSentStatusAll($event) {
    for (let iCounter = 0; iCounter < $event.length; iCounter++) {
      if (this.listOfQuoteSentStatusIds != null) {
        if (this.listOfQuoteSentStatusIds.length > 0) {
          this.listOfQuoteSentStatusIds = this.removeElemetninArray(this.listOfQuoteSentStatusIds, $event[iCounter].id)
          this.listOfQuoteSentStatusIds.push($event[iCounter].id);
        }
        else {
          this.listOfQuoteSentStatusIds.push($event[iCounter].id);
        }
      }
    }
    this.GetPricingQuotes();
  }

  onDeSelectQuoteSentStatus($event) {
    if (this.listOfQuoteSentStatusIds != null) {
      if (this.listOfQuoteSentStatusIds.length > 0) {
        this.listOfQuoteSentStatusIds = this.removeElemetninArray(this.listOfQuoteSentStatusIds, $event.id)
      }
    }
    this.GetPricingQuotes();
  }

  onDeSelectQuoteSentStatusAll($event) {
    this.listOfQuoteSentStatusIds=[];
    this.GetPricingQuotes();
  }

  onSelectMSAStatusSearch($event) {
    this.listOfMSAStatusIdsSearch = this.removeElemetninArray(this.listOfMSAStatusIdsSearch, $event.id)
    this.listOfMSAStatusIdsSearch.push($event.id);
    this.GetPricingQuotes();
  }

  onSelectMSAStatusSearchAll($event) {
    for (let iCounter = 0; iCounter < $event.length; iCounter++) {
      if (this.listOfMSAStatusIdsSearch != null) {
        if (this.listOfMSAStatusIdsSearch.length > 0) {
          this.listOfMSAStatusIdsSearch = this.removeElemetninArray(this.listOfMSAStatusIdsSearch, $event[iCounter].id)
          this.listOfMSAStatusIdsSearch.push($event[iCounter].id);
        }
        else {
          this.listOfMSAStatusIdsSearch.push($event[iCounter].id);
        }
      }
    }
    this.GetPricingQuotes();
  }

  onDeSelectMSAStatusSearch($event) {
    if (this.listOfMSAStatusIdsSearch != null) {
      if (this.listOfMSAStatusIdsSearch.length > 0) {
        this.listOfMSAStatusIdsSearch = this.removeElemetninArray(this.listOfMSAStatusIdsSearch, $event.id)
      }
    }
    this.GetPricingQuotes();
  }

  onDeSelectMSAStatusSearchAll($event) {
    this.listOfMSAStatusIdsSearch=[];
    this.GetPricingQuotes();
  }

  onSelectQuoteStatusSearch($event) {
    this.listOfQuoteStatusIdsSearch = this.removeElemetninArray(this.listOfQuoteStatusIdsSearch, $event.id)
    this.listOfQuoteStatusIdsSearch.push($event.id);
    this.GetPricingQuotes();
  }

  onSelectQuoteStatusSearchAll($event) {
    for (let iCounter = 0; iCounter < $event.length; iCounter++) {
      if (this.listOfQuoteStatusIdsSearch != null) {
        if (this.listOfQuoteStatusIdsSearch.length > 0) {
          this.listOfQuoteStatusIdsSearch = this.removeElemetninArray(this.listOfQuoteStatusIdsSearch, $event[iCounter].id)
          this.listOfQuoteStatusIdsSearch.push($event[iCounter].id);
        }
        else {
          this.listOfQuoteStatusIdsSearch.push($event[iCounter].id);
        }
      }
    }
    this.GetPricingQuotes();
  }

  onDeSelectQuoteStatusSearch($event) {
    if (this.listOfQuoteStatusIdsSearch != null) {
      if (this.listOfQuoteStatusIdsSearch.length > 0) {
        this.listOfQuoteStatusIdsSearch = this.removeElemetninArray(this.listOfQuoteStatusIdsSearch, $event.id)
      }
    }
    this.GetPricingQuotes();
  }

  onDeSelectQuoteStatusSearchAll($event) {
    this.listOfQuoteStatusIdsSearch=[];
    this.GetPricingQuotes();
  }

  removeElemetninArray(array, element) {
    return array.filter(e => e !== element);
  }

  toggleMenu(index: number) {
    this.openMenuIndex = this.openMenuIndex === index ? -1 : index; // Toggle the menu
  }

  @HostListener('document:click', ['$event'])
  onDocumentClick(event: MouseEvent) {
    const target = event.target as HTMLElement;
    if (!target.closest('.kebab-menu')) {
      this.openMenuIndex = null;
    }
  }
}
