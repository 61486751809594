import { Component, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { BriefingMasterModel } from 'src/Models/BriefingMasterModel';
import { ToolEnum } from 'src/Models/Common';
import { ExportEmailForBriefingModel } from 'src/Models/ExportEmailForBriefingModel';
import { I360Service } from 'src/Services/i360Service';
import { CheckAuth } from 'src/Services/utility.service';

@Component({
  selector: 'app-briefing-email-details',
  templateUrl: './briefing-email-details.component.html',
  styleUrls: ['./briefing-email-details.component.css']
})
export class BriefingEmailDetailsComponent implements OnInit {

  briefingForm: FormGroup;
  briefingId: number = 0;

  productId: number = 1;
  tokenKey: string = '';
  SelectedTool: string;
  currentPage: number = 1;
  pageSize:number=10;
  totalRecordCount: number = -1;

  systemUserCondition: string = '';
  isSendEmailNotificationChecked: boolean = false;
  isShowprogressSpinner: boolean = false;
  blockedDocument: boolean = false;
  isMultipleClick: boolean = false;

  briefingMasterModel = new BriefingMasterModel();
  updateDate: string;
  title: string;
  description: string;
  listOfBriefing: ExportEmailForBriefingModel[];
  isEmailData: boolean = false;
  fromSummary: any;

  constructor(private router: Router, private checkAuth: CheckAuth, private activedRoute: ActivatedRoute, private i360service: I360Service,
    private toastr: ToastrService, private titleService: Title
  ) {
    this.tokenKey = sessionStorage.getItem('tokenValue');
    this.tokenExpireCalculation(sessionStorage.getItem('appStartTime'), sessionStorage.getItem('tokenExpiryValue'));
    this.SelectedTool = sessionStorage.getItem('UserLoginTool');
    this.productId = ToolEnum[this.SelectedTool];
    this.titleService.setTitle('Briefing Details');
  }

  ngOnInit() {
    this.activedRoute.params.subscribe((params: Params) => {
      if (params['id']) {
        if (params['id'] != null) {
          this.briefingId = params['id'];

          // this.sendEmail(this.briefingId);
          this.GetBriefingData();
          this.GetBriefingEmailDataByBriefingId(this.briefingId);
          this.fromSummary = sessionStorage.getItem("fromSummary");
        }
        else {

        }
      }
    });

  }


  tokenExpireCalculation(startDate: string, tokenExpiry: string) {
    this.checkAuth.tokenExpireCalculation(startDate, tokenExpiry);
  }

  GetBriefingEmailDataByBriefingId(id) {
    this.isShowprogressSpinner = true;
    this.blockedDocument = true;
    this.i360service.GetBriefingEmailDataByBriefingId(this.tokenKey, this.briefingId,this.currentPage,this.pageSize).subscribe(
      res => {
        if (res != null) {
          if (res.status == "success") {
            this.listOfBriefing = res["emailData"];
            if (this.listOfBriefing.length > 0) {
              this.totalRecordCount = this.listOfBriefing[0].totalRecordCount;
              this.isEmailData = true;
            } else {
              this.isEmailData = false;
              this.totalRecordCount = 0;
            }
          }
        }
        this.isShowprogressSpinner = false;
        this.blockedDocument = false;
      }, error => {
        this.isShowprogressSpinner = false;
        this.blockedDocument = false;
        this.totalRecordCount = 0;
        console.log(error);
      }
    );
  }

  sendEmail(id) {
    this.blockedDocument = this.isShowprogressSpinner = true;

    this.i360service.BriefingInstantEmailSendById(this.tokenKey, id).subscribe(
      data => {
        this.blockedDocument = this.isShowprogressSpinner = false;
        if (data != null) {
          if (data.status == 'Success') {
            this.toastr.success('Mail send successfully.', 'Success')
            this.GetBriefingEmailDataByBriefingId(id);
          }
          else {
            this.toastr.error(data.message, 'Error')
            this.GetBriefingEmailDataByBriefingId(id);
          }
        }
      },
      error => {
        this.blockedDocument = this.isShowprogressSpinner = false;
      }
    );
  }

  GetBriefingData(): void {
    this.tokenKey = sessionStorage.getItem('tokenValue');
    this.isShowprogressSpinner = true;
    this.blockedDocument = true;
    this.i360service.i360BriefingMasterFindbyBriefingId(this.tokenKey, this.briefingId).subscribe(
      data => {
        if (data != null) {
          this.isShowprogressSpinner = false;
          this.blockedDocument = false;
          this.briefingMasterModel = data["briefingData"];
          if (this.briefingMasterModel != null) {

            this.briefingId = this.briefingMasterModel.briefingId;
            this.updateDate = this.dateFormat(new Date(this.briefingMasterModel.updateDate));
            this.title = this.briefingMasterModel.title;
            this.description = this.briefingMasterModel.description;
          }

        }
      }, error => {
        this.isShowprogressSpinner = false;
        this.blockedDocument = false;
        this.showMessage(error, 'Error', 'error');
      })
  }

  cancelBriefingServices() {
    if (this.fromSummary == "0")
      this.router.navigate(['admin/briefingServices/list']);
    else
      this.router.navigate(['admin/briefingServices/emailSummary']);
  }

  dateFormat(dt: Date) {
    let dd = dt.getDate().toString();
    if (parseInt(dd.toString()) < 10)
      dd = '0' + dd.toString();

    let mm = (dt.getMonth() + 1).toString();
    if (parseInt(mm.toString()) < 10)
      mm = '0' + mm.toString();

    return mm + '/' + dd + '/' + dt.getFullYear() + ' 00:00:00';
  }

  showMessage(msg: string, msgTitle: string, msgType: string) {
    if (msgType == 'success') {
      setTimeout(() => this.toastr.success(msg, msgTitle));
    }
    else if (msgType == 'error') {
      setTimeout(() => this.toastr.error(msg, msgTitle));
    }
  }

  pageChanged($event: any) {
    this.currentPage = $event.page;
    this.GetBriefingEmailDataByBriefingId(this.briefingId);
  }
}
